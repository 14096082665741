import React from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import Login from "./containers/Login";
import AdminRoutes from "./routes/AdminRoutes";
import ControllerRoutes from "./routes/ControllerRoutes";
import './i18configs.js'

import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyAX1vUmSAISNIfWC3SAYGu5dvo9xqEpVIQ",
  authDomain: "auction-web-df908.firebaseapp.com",
  databaseURL: "https://auction-web-df908-default-rtdb.firebaseio.com",
  projectId: "auction-web-df908",
  storageBucket: "auction-web-df908.appspot.com",
  messagingSenderId: "808131192380",
  appId: "1:808131192380:web:2fe4a23941811d87f44539",
  measurementId: "G-2KWM05K3L9"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);



const isUser = { isAuthenticated: false };

const isUserAuthenticate = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = rest.props;
  let isAuthenticated = user;
  isUserAuthenticate();
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isAuthenticated || (isUser && isUser.isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default function BasicExample() {
  return (
    <>
    
      <AdminRoutes />
      {/* <ControllerRoutes /> */}
    </>
  );
}


// "start": "react-scripts --openssl-legacy-provider start",