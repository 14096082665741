import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TextField, Grid } from '@material-ui/core';
import firebase from 'firebase';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { create_post_options, update_post_options } from '../../../api/index';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function CarFeatureDialog({
  openModal,
  setOpen,
  setModalTitle,
  setIsOpenSnack,
  modalTitle,
  rowData,
  setIsOPenSnackError,
  getAllCarFeatures,
  setRowData,
}) {
  const [carFeature, setCarFeature] = useState('');

  useEffect(() => {
    if (rowData && modalTitle !== 'Create Car Feature') {
      console.log(rowData, 'rowData');
      setCarFeature(rowData?.name);
    }
  }, [modalTitle, rowData]);

  console.log(rowData, 'rowDatarowData');

  const handleClose = () => {
    setOpen(false);
    setModalTitle(null);
    setCarFeature('');
    setRowData(null);
  };

  const handleSave = () => {
    if (carFeature === '') setIsOPenSnackError(true);
    else {
      if (modalTitle !== 'Create Car Feature') {
        let paylod = {
          name: carFeature,
        };

        axios
          .put(`${update_post_options}/${rowData._id}`, paylod)
          .then((res) => {
            handleClose();
            getAllCarFeatures();
            setIsOpenSnack(true);
            setCarFeature('');
          })
          .catch((err) => {
            console.log(err, 'errrr');
          });
      } else {
        let paylod = {
          name: carFeature,
        };
        axios
          .post(create_post_options, paylod)
          .then((res) => {
            console.log(res, 'sadsadsadasd');
            handleClose();
            getAllCarFeatures();
            setIsOpenSnack(true);
            setOpen(false);
            setCarFeature('');
          })
          .catch((err) => {
            console.log(err, 'errrr');
          });
      }
    }
  };

  const renderEnglish = () => {
    return (
      <>
        <Grid
          container
          spacing={2}
          alignItems='flex-end'
          style={{ marginTop: '1%' }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant='outlined'
              placeholder='Car Feature'
              label='Car Feature'
              size='small'
              fullWidth
              name=''
              value={carFeature}
              onChange={(e) => setCarFeature(e.target.value)}
              required
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>{modalTitle}</DialogTitle>
        <DialogContent style={{ minWidth: '600px', minHeight: '150px' }}>
          {renderEnglish()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' variant='contained'>
            Cancel
          </Button>
          <Button onClick={handleSave} color='primary' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
