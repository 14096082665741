import Swal from 'sweetalert2';

class ConfrimWinner {
	
	handleClick(doAlert, deleteSomehing, y) {
		if (doAlert) {
			Swal.fire({
				title: y,
				text: `Are sure to make this user  winner`,
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#9f1701',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Confirm'
			}).then(result => {
				if (result.isConfirmed) {
					// setTimeout(() => {
					// 	alert('Winner Selected')
					// }, 2000);
					
				}
			});
		}
	}
}

export default new ConfrimWinner();
