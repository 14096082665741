import React, { useState, useEffect } from "react";
import { Container, Paper } from "@material-ui/core";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../react_dates_overrides.css";
import { DateRangePicker } from "react-dates";
import NavBar from "../../../navbar/AdminNavigation";
import CardsStats from "./CardStats";
import { useStyles } from "../styles";
import moment from "moment";
import { Loader } from "../../../components";
import UsersChart from "./UsersChart";
import axios from "axios";
import { get_dashboard_stats } from "./../../../api/index";

const DashBoard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const [dateRange, setdateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [final, setFinal] = useState([]);
  const [cardStats, setCardStats] = useState({
    carsSold: 0,
    totalCarsPosted: 0,
    subscribedSellers: 0,
    unsubscribedSeller: 0,
    totalUsers: 0,
    carsByAdmin: 0,
  });

  let tempArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
  ];

  useEffect(() => {
    axios
      .get(get_dashboard_stats)
      .then((res) => {
        let result = res.data.data;
        console.log(result, "resultresultresult");
        setCardStats({
          carsSold:result.totalCarsSold,
          totalCarsPosted:result.totalPosts,
          subscribedSellers:result.subscribedSeller,
          unsubscribedSeller:result.unSubscribedSeller,
          totalUsers: result.allUsers,
          carsByAdmin:result.adminCars,
      
        });
      })
      .catch((err) => {});
  }, []);

  // useEffect(() => {
  //   let temp = [];
  //   allUsers.map((item, index) => {
  //     item.tickets.map((val) => {
  //       let num = new Date(val.bookingDate);
  //       const starting = moment(num).format("DD-MM-YYYY");
  //       let month = num.getMonth() + 1;
  //       temp.push(month);
  //     });
  //   });

  //   let a = [],
  //     b = [],
  //     arr = [...temp], // clone array so we don't change the original when using .sort()
  //     prev;

  //   arr.sort();
  //   for (let element of arr) {
  //     if (element !== prev) {
  //       a.push(element);
  //       b.push(1);
  //     } else ++b[b.length - 1];
  //     prev = element;
  //   }
  //   let tempArrFinal = [];
  //   a.map((item, index) => {
  //     tempArrFinal.push({
  //       category: tempArr[item - 1],
  //       first: b[index],
  //     });
  //   });
  //   setFinal(tempArrFinal);
  // }, [allUsers]);

  const handleSeach = () => {
    let filtered = [];
    setIsLoading(true);
    events.map((val) => {
      let date = new Date();
      let datSetter = val.eventDate.split("-");
      date.setDate(datSetter[0]);
      date.setMonth(datSetter[1] - 1);
      date.getFullYear(datSetter[2]);

      let diff = moment(
        `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
      ).isBetween(dateRange.startDate, dateRange.endDate);
      if (diff) {
        filtered.push(val);
      }
    });
    // setTimeout(() => {
    setFilteredEvents(filtered);
    setIsLoading(false);
    // }, 2000);
  };

  return (
    <>
      <NavBar>
        <Loader isloading={isLoading} />
        <div>
          <Container maxWidth="xl">
            {/* <Paper className={classes.paperRoot}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={8} md={8}>
                  <DateRangePicker
                    isOutsideRange={() => false}
                    startDatePlaceholderText="Start Date"
                    startDate={startDate}
                    onDatesChange={handleOnDateChange}
                    endDatePlaceholderText="End Date"
                    endDate={endDate}
                    numberOfMonths={2}
                    displayFormat="MMM D Y"
                    showClearDates={true}
                    focusedInput={focus}
                    onFocusChange={(focus) => setFocus(focus)}
                    startDateId="startDateMookh"
                    endDateId="endDateMookh"
                    minimumNights={0}
                    small

                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.createBtn}
                      style={{ textTransform: "none" }}
                      onClick={handleSeach}
                    >
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Paper> */}

            <CardsStats cardStats={cardStats} />

            <Paper style={{ marginTop: "10px" }}>
              {final.length > 0 && <UsersChart data={final} />}
            </Paper>
          </Container>
        </div>
      </NavBar>
    </>
  );
};

export default DashBoard;
