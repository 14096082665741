import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    searchContainer: {
      display: "flex",
      alignItems: "center",
    },
    paperRoot: {
      padding: "20px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    zone: {
      marginBottom: 0,
      fontWeight: 700,
      color: "#1186fa",
      marginRight: 10,
    },
    inputStyle: {
      "&.MuiFormControl-root .MuiInputBase-root": {
        background: "#f6f7fb",
        borderRadius: "12px",
      },
      "&.MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "#eaeef4",
        },
    },
    textttt: {
      "&.MuiListItemText-root .MuiListItemText-primary": {
        fontWeight: 900,
        fontSize: "22px",
      },
    },
    percentTypo: {
      color: "#1286f9",
      fontWeight: 600,
      fontSize: "12px",
    },
    monthTypo: {
      color: "#b8b9c1",
      fontWeight: 600,
      fontSize: "12px",
    },
    listItemContainer: {
      "&.MuiListItemAvatar-root .MuiAvatar-root": {
        background: "#e6f2fe",
        borderRadius: "20%",
        width: "45px",
        height: "50px",
      },
      "&.MuiListItemAvatar-root .MuiAvatar-root .MuiTypography-root": {
        color: "#0a83f9",
        fontSize: "16px",
        fontWeight: 700,
      },
    },
    activeBtnContainer: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        margin: "0 0 10px 10px",
      },
    },
    activeBtnContainer2: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginRight: 10,
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    dateBtnsActive: {
      width: "80px",
      textTransform: "none",
      color: "#0a81f9",
      fontWeight: 700,
      background: "#fff",
      borderRadius: "5px",
    },
    dateBtnsInActive: {
      width: "80px",
      textTransform: "none",
      color: "#b1b3b8",
      fontWeight: 700,
      background: "none",
      borderRadius: "5px",
    },
    secondDateBtnsActive: {
      width: "80px",
      textTransform: "none",
      color: "#0a81f9",
      fontWeight: 700,
      background: "#f6f7fb",
      borderRadius: "5px",
    },
    targetHead: {
      "&.MuiListItemText-root .MuiTypography-root": {
        fontWeight: "bold",
        fontSize: "20px",
      },
    },
    reach: {
      fontSize: "25px",
      fontWeight: "bold",
      display: "flex",
    },
    cardContainer: {
      background: "#f6f7fb",
      marginRight: 10,
      padding: "10px",
      paddingLeft: "50px",
    },
    organic: {
      "&.MuiListItemText-root .MuiTypography-root": {
        color: "#abadb8",
        fontSize: "12px",
      },
    },
    textPri: {
      "&.MuiListItemText-root .MuiListItemText-primary": {
        fontWeight: "bold",
        fontSize: "18px",
      },
    },
    reportBtn: {
      background: "#f1f4fd",
      padding: "5px 10px",
      textTransform: "none",
      color: "#3698fa",
      "&:hover": {
        background: "#f1f4fd",
      },
    },
    inputlabel: {
      margin: "8px 0",
      fontSize: "13px",
    },
    userRoleStyle: {
      width: "100%",
      "&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root": {
        padding: "8px 12px",
      },
    },
  }));