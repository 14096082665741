import React from "react";
import { Icon, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../styles";

const CardsStats = ({ cardStats }) => {
  const classes = useStyles();

  const data = [
    {
      title: "Cars Sold",
      count: cardStats.carsSold,
      icon: "local_activity",
      background: "#17a2b8",
      color: "rgb(204 54 138)",
    },
    {
      title: "Total Cars Posted",
      count: cardStats.totalCarsPosted,
      icon: "festival",
      background: "#28a745",
      color: "rgb(218 87 243)",
    },
    {
      title: "Subscribed Sellers",
      count: cardStats.subscribedSellers,
      icon: "group",
      background: "#238dff",
      color: "rgb(218 87 243)",
    },
    {
      title: "UnSubscribed Sellers",
      count: cardStats.unsubscribedSeller,
      icon: "group",
      background: "#238dff",
      color: "rgb(218 87 243)",
    },
    {
      title: "Total Users",
      count: cardStats.totalUsers,
      icon: "attach_money",
      background: "#dc3545",
      color: "rgb(28 154 200)",
    },
    {
      title: "Cars Posted By Admin",
      count: cardStats.carsByAdmin,
      icon: "attach_money",
      background: "#dc3545",
      color: "rgb(28 154 200)",
    },
  ];

  return (
    <div style={{ margin: "20px 0" }}>
      {/* <Grid container spacing={3}> */}

      <div className={classes.cardContainer}>
        {data.map((item) => {
          return (
            // <Grid item xs={12} sm={6} md={2}>
            <div
              className={classes.outerContainer}
              style={{ background: item.background }}
            >
              <div className={classes.detailContainer}>
                <div>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: "1.3rem",
                      color: "#fff",
                      marginBottom: "10px",
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      color: "#fff",
                      fontWeight: 700,
                      fontSize: "2.2rem",
                    }}
                  >
                    {item.count}
                  </Typography>
                </div>
                <div>
                  <Icon style={{ color: "#00000026", fontSize: "4em" }}>
                    {item.icon}
                  </Icon>
                </div>
              </div>
            </div>
            // </Grid>
          );
        })}
      </div>
    </div>
  );
};

export default CardsStats;
