import React, { useState, useEffect } from 'react';
import NavBar from '../../../navbar/AdminNavigation';
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Switch,
} from '@material-ui/core';
import { useStyles } from '../styles';
import firebase from 'firebase';
import {
  Loader,
  successHelper,
  SuccesSnack,
  ErrorSnack,
} from '../../../components';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../react_dates_overrides.css';
import PostDialog from './PostDialog';
import axios from 'axios';
import {
  get_add_post,
  get_post_options,
  delete_add_post,
  update_add_post,
} from '../../../api';
import Swal from 'sweetalert2';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

const AddPost = () => {
  const classes = useStyles();
  const history = useHistory();
  const [allPosts, setAllPosts] = React.useState([]);
  const [isOpenSnack, setIsOpenSnack] = React.useState(false);
  const [isOpenSnackError, setIsOPenSnackError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem('user'));
  const tableHeading = [
    'No',
    'Vehicle Name',
    'Registered City',
    'Registration Number',
    'Color',
    userData?.userType !== 'controller' ? 'Approve' : null,
    userData?.userType !== 'controller' ? 'Action' : null,
  ];
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [isOpenModal, setIsOPenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [carFeatures, setCarFeatures] = useState([]);
  const [snackMessage, setSnackMessage] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [filter, setFilter] = useState('all');
  const [filterArr, setFilterArr] = useState([]);

  const [inputValue, setInputValue] = useState({
    registeredCity: '',
    vehicleName: '',
    registrationNumber: '',
    color: '',
    milage: '',
    price: '',
    description: '',
    chasisNo: '',
  });

  const getAllAddPosts = () => {
    setIsLoading(true);
    axios
      .get(get_add_post)
      .then((res) => {
        setIsLoading(false);
        setAllPosts(res.data.data);
        setFilterArr(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllAddPosts();
    axios
      .get(get_post_options)
      .then((res) => {
        let tempArr = [];
        res.data.data.map((val) => {
          tempArr.push({ label: val.name, value: val.name });
        });
        setCarFeatures(tempArr);
      })
      .catch((err) => {});
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAdd = (e, ind) => {
    setIsOPenModal(true);
    setModalType('Create Post');
    setSelectedFeatures([]);
    setInputValue({
      registeredCity: '',
      vehicleName: '',
      registrationNumber: '',
      color: '',
      milage: '',
      price: '',
      description: '',
      chasisNo: '',
    });
    setPostImages([]);
    // let tempAr = [...allPosts]
    // tempAr.push({ question: '', answer: '' })
    // setAllPosts(tempAr)
  };
  const handleRowClick = (e, row, key, ind) => {
    e.stopPropagation();
    setIsOPenModal(true);
    setRowData(row);
    setSelectedRowIndex(key);
    setModalType('Update Post');
  };

  const handleDelete = (e, row) => {
    e.stopPropagation();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${delete_add_post}/${row._id}`)
          .then((res) => {
            Swal.fire('Deleted!', 'Post deleted successfully.', 'success');
            getAllAddPosts();
          })
          .catch((err) => {
            console.log(err, 'faqqqaaaa');
          });
      }
    });
  };

  const handleChangeFilter = (e) => {
    let valueee = e.target.value;

    setFilter(valueee);

    if (valueee == 'all') {
      setFilterArr(allPosts);
    } else if (valueee == 'approval') {
      let newArr = allPosts.filter((item) => {
        return !item.post.isApprove ? item : null;
      });
      setFilterArr(newArr);
    } else {
      let myListingArr = allPosts.filter((item) =>
        item?.post.userId == userData.userID ? item : null
      );
      setFilterArr(myListingArr);
    }
  };

  const handleEditStatus = (e, row) => {
    e.stopPropagation();
    let approveParam = {
      isApprove: !row.post.isApprove,
    };
    setIsLoading(true);
    axios
      .put(`${update_add_post}/${row?.post._id}`, approveParam)
      .then((res) => {
        getAllAddPosts();
        setIsOpenSnack(true);
      })
      .catch((err) => {
        console.log(err, 'errrr');
      });
  };

  return (
    <NavBar>
      <Loader isloading={isLoading} />
      <Container maxWidth='xl'>
        <PostDialog
          openModal={isOpenModal}
          setOpen={setIsOPenModal}
          setModalTitle={setModalType}
          data={filterArr}
          setIsOpenSnack={setIsOpenSnack}
          modalTitle={modalType}
          rowData={rowData}
          selectedRowIndex={selectedRowIndex}
          setIsOPenSnackError={setIsOPenSnackError}
          getAllAddPosts={getAllAddPosts}
          carFeatures={carFeatures}
          setSnackMessage={setSnackMessage}
          setCarFeatures={setCarFeatures}
          setSelectedFeatures={setSelectedFeatures}
          selectedFeatures={selectedFeatures}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setPostImages={setPostImages}
          postImages={postImages}
        />
        <ErrorSnack
          open={isOpenSnackError}
          message={snackMessage}
          closeSnack={setIsOPenSnackError}
        />
        <SuccesSnack
          open={isOpenSnack}
          message={'Add Post Saved Succesfully'}
          closeSnack={setIsOpenSnack}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <FormControl size='small' style={{ width: '20%', marginRight: 10 }}>
            <Select
              value={filter}
              onChange={handleChangeFilter}
              placeholder='Comapny'
              displayEmpty
              variant='outlined'
              fullWidth
              size='small'
            >
              <MenuItem value={'all'}>All</MenuItem>
              <MenuItem value={'myListing'}>My Listing</MenuItem>
              <MenuItem value={'approval'}>Waiting For Approval</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant='contained'
            size='small'
            color='primary'
            // className={classes.createBtn}
            style={{
              textTransform: 'none',
              padding: '8px 40px',
              // width: "10%",
              marginBottom: '10px',
              float: 'right',
              marginTop: '1%',
              marginRight: '1%',
            }}
            onClick={handleAdd}
          >
            Add Post
          </Button>
        </div>

        <Paper>
          <TableContainer component={Paper} style={{ padding: '20px' }}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  {tableHeading &&
                    tableHeading?.map((heading, index) => {
                      return (
                        <TableCell
                          align='center'
                          style={{
                            fontWeight: 'bold',
                            fontSize: '13px',
                            padding: '6px',
                          }}
                        >
                          {heading}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterArr &&
                  filterArr
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, ind) => (
                      <TableRow
                        key={ind}
                        className={classes.tableRow}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          history.push({
                            pathname: '/PostDetails',
                            state: row,
                          })
                        }
                      >
                        <TableCell align='center' className={classes.rowName}>
                          {ind + 1}
                        </TableCell>
                        <TableCell align='center' className={classes.rowName}>
                          {row?.post?.vehicleName}
                        </TableCell>
                        <TableCell align='center' className={classes.rowName}>
                          {row?.post?.registeredCity}
                        </TableCell>
                        <TableCell align='center' className={classes.rowName}>
                          {row?.post?.registrationNumber}
                        </TableCell>
                        <TableCell align='center' className={classes.rowName}>
                          {row?.post?.color}
                        </TableCell>
                        {userData?.userType !== 'controller' ? (
                          <>
                            <TableCell
                              align='center'
                              className={classes.rowName}
                            >
                              <Switch
                                size='small'
                                checked={row?.post?.isApprove}
                                onClick={(e) => handleEditStatus(e, row)}
                              />
                            </TableCell>
                            <TableCell
                              align='center'
                              className={classes.rowName}
                            >
                              <EditIcon
                                onClick={(e) => handleRowClick(e, row, ind)}
                              />
                              <DeleteIcon
                                style={{ color: 'red' }}
                                onClick={(e) => handleDelete(e, row?.post)}
                              />
                            </TableCell>
                          </>
                        ) : null}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={filterArr.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>

        <Grid container spacing={2}></Grid>
      </Container>
    </NavBar>
  );
};

export default AddPost;
