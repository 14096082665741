import React, { useState, useEffect } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  TextField,
  Typography,
  Switch,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../styles";
import {
  Loader,
  successHelper,
  ConfirmWinner,
  ErrorHelper,
} from "../../../components";
import moment from "moment";
import firebase from "firebase";
import Swal from "sweetalert2";
import axios from "axios";
import { update_tickets } from "../../../api";
import { send_email } from "./../../../api/index";

const Users = ({
  eventTable,
  eventDetails,
  fireBaseKey,
  alllangData,
  getEventData,
  params,
  getEventDetails,
}) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [tableData, setTableData] = useState({
    name: "",
    email: "",
    password: "",
    status: true,
  });
  const [allBokings, setAllBookings] = useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [winnerCount, setWinnerCount] = useState(0);

  const [users, setUsers] = React.useState(eventTable);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const tableHeading = [
    "ID",
    "Name",
    "Email",
    "Booking Date",
    "Ticket Number",
    "Prize No",
  ];
  let currentUser = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    setUsers(eventTable);
  }, [eventTable]);

  useEffect(() => {
    if (eventDetails !== undefined) {
      if (eventDetails?.tickets?.length > 0) {
        eventDetails.tickets.map((item) => {
          console.log(item, "adlksajdlksa");
          if (item.winner !== null) {
            setWinnerCount(winnerCount + 1);
          }
        });
      }
    }
  }, [eventTable]);

  console.log(winnerCount, "counttttttt");

  console.log(eventTable, "eventTable");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSwitch = (key) => {
    // firebase.database().ref(`controllers/${key}`).update({
    //   ...allControllers[key],
    //   status: !allControllers[key].status
    // }).then(res => {
    //   alert('Updated Succesfully')
    // }).catch(err => {
    // })
  };

  // for firebaseIntegration
  // let users = Object.keys(allUsers)

  let handleRowClick = (row, index) => {
    console.log(users[index], "asdsadasdasd");
    if (users[index].winner == null) {
      setSelectedRow(row);
    }

    // if (currentUser.userType == "admin") {
    //   console.log(row, "rowrowrowrow");
    //   let temp = [...users];
    //   temp.map((val, ind) => {
    //     if (index == ind) {
    //       if (temp[ind].winner == "true") {
    //         temp[ind] = {
    //           ...val,
    //           winner: "false",
    //         };
    //         setSelectedRow(null);
    //       } else {
    //         temp[ind] = {
    //           ...val,
    //           winner: "true",
    //         };
    //         setSelectedRow(row);
    //         setSelectedTicket(row.ticketNo);
    //       }
    //     } else {
    //       temp[ind] = {
    //         ...val,
    //         winner: false,
    //       };
    //     }
    //   });
    //   setUsers(temp);
    // }
  };

  let handleWinner = () => {
    if (users && users.length > 0) {
      let payload = {
        eventId: params.id,
        prize: `${winnerCount + 1} Prize`,
        winner: selectedRow,
        ticketNumber: selectedRow.ticketNumber,
        userEmail: selectedRow.userDetails.email,
      };

      console.log(payload, "Asdaksdjlad");

      if (selectedRow.winner == null) {
        Swal.fire({
          title: selectedRow.userDetails.name,
          text: `Are you sure? you want to make this user winner for ${payload.prize}`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#9f1701",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm",
        }).then((result) => {
          if (result?.isConfirmed) {
            setIsLoading(true);
            axios
              .post(update_tickets, payload)
              .then((res) => {
                console.log(res, "sadljdlksajlk");
                successHelper.handleSuccess(res.data.message, true);
                setSelectedRow(null);
                getEventDetails();

                axios
                  .post(send_email, {
                    email: selectedRow.userDetails.email,
                    subject: "Winner Selected",
                    text: `Congrats! you have selected winner for ${payload.prize} against ticket number: ${selectedRow.ticketNumber}`,
                  })
                  .then(function (response) {
                    setIsLoading(false);
                    console.log(response);
                  })
                  .catch(function (error) {
                    setIsLoading(false);
                    console.log(error);
                  });
              })
              .catch((err) => {
                console.log(err, "asdlkajdlksa");
                setIsLoading(false);
              });
          }
        });
      } else {
        ErrorHelper.handleErrors("Same ticket cannot win again", true);
      }
    } else {
      ErrorHelper.handleErrors("No Tickets Found", true);
    }
  };

  // let handleWinner = () => {
  //   if (users && users.length > 0) {
  //     if (row !== null) {
  //       let isWonAllPrize = eventDetails.eventPrizes.every(
  //         (value) => value.isWon == true
  //       );
  //       let currentPrize = eventDetails.eventPrizes.find((item) => !item.isWon);
  //       console.log(eventDetails.eventPrizes, isWonAllPrize, "currentPrize");
  //       if (!isWonAllPrize) {
  //         let participatedOBJ = null;
  //         firebase
  //           .database()
  //           .ref("participatedEvents")
  //           .on("value", (snapshot) => {
  //             let data = snapshot.val() ? snapshot.val() : {};
  //             console.log(data, "participatedOBJ");
  //             if (data) {
  //               console.log(data, "participatedOBJ");
  //               participatedOBJ = data[row.userId];
  //             }
  //           });

  //         Swal.fire({
  //           title: row?.name,
  //           text: `Are sure to make this user  winner for ${currentPrize?.prizeNo}`,
  //           icon: "question",
  //           showCancelButton: true,
  //           confirmButtonColor: "#9f1701",
  //           cancelButtonColor: "#d33",
  //           confirmButtonText: "Confirm",
  //         }).then((result) => {
  //           console.log(row.userId, "row.userId");
  //           if (result?.isConfirmed) {
  //             firebase
  //               .database()
  //               .ref(`participatedEvents/${row.userId}/${fireBaseKey}`)
  //               .update({
  //                 ...participatedOBJ[fireBaseKey],
  //                 winner: row,
  //                 prize: currentPrize.prizeNo,
  //               })
  //               .then((res) => {
  //                 axios
  //                   .post(
  //                     "https://auction-web-japan.herokuapp.com/api/sendEmail ",
  //                     {
  //                       email: row.email,
  //                       subject: "Winner Selected",
  //                       text: `Congrats! you have selected winner for ${currentPrize.prizeNo} ${currentPrize.vehicleName} against ticket ${row.ticketNo}`,
  //                     }
  //                   )
  //                   .then(function (response) {
  //                     console.log(response);
  //                   })
  //                   .catch(function (error) {
  //                     console.log(error);
  //                   });
  //                 // successHelper.handleSuccess('Winner Selected', true)
  //               })
  //               .catch((err) => {
  //                 console.log(err, "errrr");
  //               });
  //             let findCurrentPrizeIndex = eventDetails.eventPrizes.findIndex(
  //               (prize) => prize.prizeNo === currentPrize.prizeNo
  //             );
  //             let updatedUsers = [...users];
  //             let findSelectedUserIndex = eventDetails.participant.findIndex(
  //               (user) => user.ticketNo === selectedTicket
  //             );
  //             updatedUsers[findSelectedUserIndex] = {
  //               ...updatedUsers[findSelectedUserIndex],
  //               isWon: true,
  //               prizeNo: currentPrize.prizeNo,
  //             };
  //             let allPrizes = [...eventDetails.eventPrizes];
  //             allPrizes[findCurrentPrizeIndex] = {
  //               ...allPrizes[findCurrentPrizeIndex],
  //               isWon: true,
  //               user: row,
  //             };
  //             let params = {
  //               ...alllangData,
  //               winner: { ...row, winner: true },
  //               participant: updatedUsers,
  //               eventPrizes: allPrizes,
  //             };
  //             firebase
  //               .database()
  //               .ref(`events/${fireBaseKey}`)
  //               .update({ ...params })
  //               .then((res) => {
  //                 getEventData();
  //                 successHelper.handleSuccess("Winner Selected", true);
  //               })
  //               .catch((err) => {
  //                 console.log(err, "errrr");
  //               });
  //           }
  //         });
  //       } else {
  //         ErrorHelper.handleErrors(
  //           "You have already selected winner for this event",
  //           true
  //         );
  //       }
  //     } else {
  //       ErrorHelper.handleErrors("Please Select Participant", true);
  //     }
  //   } else {
  //     ErrorHelper.handleErrors("No Participants Found", true);
  //   }
  // };

  let handleWinnerRandom = () => {
    if (users && users.length > 0) {
      const random = Math.floor(Math.random() * users.length);
      console.log(users[random], "adlksajdlsajsalkd");

      let payload = {
        eventId: params.id,
        prize: `${winnerCount + 1} Prize`,
        winner: users[random],
        ticketNumber: users[random].ticketNumber,
        userEmail: users[random].userDetails.email,
      };

      console.log(payload, "Asdaksdjlad");

      if (users[random].winner == null) {
        Swal.fire({
          title: users[random].userDetails.name,
          text: `Are you sure? you want to make this user winner for ${payload.prize}`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#9f1701",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm",
        }).then((result) => {
          if (result?.isConfirmed) {
            setIsLoading(true);
            axios
              .post(update_tickets, payload)
              .then((res) => {
                console.log(res, "sadljdlksajlk");
                setIsLoading(false);
                successHelper.handleSuccess(res.data.message, true);
                getEventDetails();

                axios
                  .post(send_email, {
                    email: users[random].userDetails.email,
                    subject: "Winner Selected",
                    text: `Congrats! you have selected winner for ${payload.prize} against ticket number: ${users[random].ticketNumber}`,
                  })
                  .then(function (response) {
                    setIsLoading(false);
                    console.log(response);
                  })
                  .catch(function (error) {
                    setIsLoading(false);
                    console.log(error);
                  });
              })
              .catch((err) => {
                console.log(err, "asdlkajdlksa");
                setIsLoading(false);
              });
          }
        });
      } else {
        ErrorHelper.handleErrors("Same ticket cannot win again", true);
      }
    } else {
      ErrorHelper.handleErrors("No Tickets Found", true);
    }
  };

  // if (users && users.length > 0) {
  //   let isWonAllPrize = eventDetails.eventPrizes.every(
  //     (value) => value.isWon == true
  //   );
  //   let currentPrize = eventDetails.eventPrizes.find((item) => !item.isWon);
  //   if (!isWonAllPrize) {
  //     var item = users[Math.floor(Math.random() * users.length)];
  //     if (users?.isWon) {
  //       ErrorHelper.handleErrors(
  //         "Please select again this ticket has already won a prize",
  //         true
  //       );
  //       return;
  //     }
  //     setSelectedTicket(item.ticketNo);
  //     let participatedOBJ = null;
  //     firebase
  //       .database()
  //       .ref("participatedEvents")
  //       .on("value", (snapshot) => {
  //         let data = snapshot.val() ? snapshot.val() : {};
  //         if (data) {
  //           participatedOBJ = data[item?.userId];
  //         }
  //       });
  //     let params = {
  //       ...eventDetails,
  //       winner: { ...item, winner: true },
  //       participant: users,
  //     };
  //     Swal.fire({
  //       title: item.name,
  //       text: `Are sure to make this user  winner for ${currentPrize.prizeNo}`,
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonColor: "#9f1701",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Confirm",
  //     }).then((result) => {
  //       if (result?.isConfirmed) {
  //         firebase
  //           .database()
  //           .ref(`participatedEvents/${item.userId}/${fireBaseKey}`)
  //           .update({
  //             ...participatedOBJ[fireBaseKey],
  //             winner: item,
  //             prize: currentPrize.prizeNo,
  //           })
  //           .then((res) => {
  //             axios
  //               .post(
  //                 "https://auction-web-japan.herokuapp.com/api/sendEmail ",
  //                 {
  //                   email: item.email,
  //                   subject: "Winner Selected",
  //                   text: `Congrats! you have selected winner for ${currentPrize.prizeNo} ${currentPrize.vehicleName} against ticket ${item.ticketNo}`,
  //                 }
  //               )
  //               .then(function (response) {
  //                 console.log(response);
  //               })
  //               .catch(function (error) {
  //                 console.log(error);
  //               });
  //             // successHelper.handleSuccess('Winner Selected', true)
  //           })
  //           .catch((err) => {
  //             console.log(err, "errrr");
  //           });
  //         let findCurrentPrizeIndex = eventDetails.eventPrizes.findIndex(
  //           (prize) => prize.prizeNo === currentPrize.prizeNo
  //         );
  //         let allPrizes = [...eventDetails.eventPrizes];
  //         allPrizes[findCurrentPrizeIndex] = {
  //           ...allPrizes[findCurrentPrizeIndex],
  //           isWon: true,
  //           user: item,
  //         };
  //         let findSelectedUserIndex = eventDetails.participant.findIndex(
  //           (user) => user.ticketNo === item.ticketNo
  //         );
  //         let updatedUsers = [...users];
  //         updatedUsers[findSelectedUserIndex] = {
  //           ...updatedUsers[findSelectedUserIndex],
  //           isWon: true,
  //           prizeNo: currentPrize.prizeNo,
  //         };
  //         let params = {
  //           ...alllangData,
  //           winner: { ...row, winner: true },
  //           participant: [...updatedUsers],
  //           eventPrizes: allPrizes,
  //         };

  //         firebase
  //           .database()
  //           .ref(`events/${fireBaseKey}`)
  //           .update({ ...params })
  //           .then((res) => {
  //             getEventData();
  //             successHelper.handleSuccess("Winner Selected", true);
  //           })
  //           .catch((err) => {
  //             console.log(err, "errrr");
  //           });
  //       }
  //     });
  //   } else {
  //     ErrorHelper.handleErrors(
  //       "You have already selected winner for this event",
  //       true
  //     );
  //   }
  // } else {
  //   ErrorHelper.handleErrors("No Participants Found", true);
  // }
  const handleSearch = (e) => {
    let filtered = eventTable.filter((item) => {
      return item?.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setUsers(filtered);
  };

  return (
    <>
      <Loader isloading={isLoading} />
      <Paper>
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "8px",
            marginRight: "16px",
          }}
        >
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              placeholder="Search"
              label="Search"
              size="small"
              fullWidth
              // value={selectedCity}
              onChange={(e) => handleSearch(e)}
            />
          </Grid>
        </Grid>

        <TableContainer component={Paper} style={{ padding: "20px" }}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tableHeading.map((heading, index) => {
                  return (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        padding: "6px",
                      }}
                    >
                      {heading}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.length > 0 &&
                users.map((row, ind) => {
                  console.log(row, "aslkdjsalkdjsalkd");
                  // if (row.isPaidPrice != "PayLater") {
                  return (
                    <TableRow
                      key={ind}
                      className={classes.tableRow}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          row.winner !== null
                            ? "#448AFF"
                            : selectedRow?.ticketNumber == row.ticketNumber
                            ? "pink"
                            : null,
                      }}
                      onClick={(_) => handleRowClick(row, ind)}
                    >
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          color: row.isWon == true ? "#fff" : null,
                        }}
                        className={classes.rowName}
                      >
                        {ind + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          color: row.isWon == true ? "#fff" : null,
                        }}
                        className={classes.rowName}
                      >
                        {row?.userDetails?.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          color: row.isWon == true ? "#fff" : null,
                        }}
                        className={classes.rowName}
                      >
                        {row?.userDetails?.email}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          color: row.isWon == true ? "#fff" : null,
                        }}
                        className={classes.rowName}
                      >
                        {moment(row.date).format("D MMMM YYYY")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          color: row.isWon == true ? "#fff" : null,
                        }}
                        className={classes.rowName}
                      >
                        {row.ticketNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          cursor: "pointer",
                          color: row.isWon == true ? "#fff" : null,
                        }}
                        className={classes.rowName}
                      >
                        {row.winner !== null ? row.prize : "-"}
                      </TableCell>
                    </TableRow>
                  );
                  // }
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={users?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
      {currentUser?.userType == "admin" ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "1%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={eventDetails?.eventPrizes?.length == winnerCount}
            onClick={handleWinnerRandom}
            fullWidth
            style={{ width: "30%", textAlign: "center", height: "35%" }}
            className={classes.winnerButton}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {"Choose Winner Randomly"}
          </Button>
          <Button
            onClick={handleWinner}
            disabled={eventDetails?.eventPrizes?.length == winnerCount}
            fullWidth
            style={{ width: "30%", textAlign: "center", height: "35%" }}
            className={classes.winnerButton}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {"Confirm Winner"}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default Users;
