import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase';
import { Loader, successHelper, ErrorHelper } from '../components';
import { admin_login } from '../api';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://t4.ftcdn.net/jpg/02/20/78/97/360_F_220789776_jDLKLiAmjX1jnf92mT4TCctWJgtpJbbt.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    // margin: theme.spacing(8, 4),
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export default function SignInSide({ history }) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();

  const loginUser = (event) => {
    event.preventDefault();
    if (password == '' || email == '') {
      ErrorHelper.handleErrors(`Invalid Email or Password`, true);
    } else {
      setIsLoading(true);
      let paylod = {
        email,
        password,
      };
      axios
        .post(admin_login, paylod)
        .then((res) => {
          setIsLoading(false);
          if (res.data.message.userDetails.email === 'admin@admin.com') {
            localStorage.setItem(
              'user',
              JSON.stringify({
                userType: 'admin',
                userID: res.data.message.userDetails._id,
                user: { email: '', name: 'Admin' },
              })
            );
            history.push('/dashboard');
          } else {
            if (res.data.message.userDetails.status) {
              localStorage.setItem(
                'user',
                JSON.stringify({
                  userType: 'controller',
                  userID: res.data.message.userDetails._id,
                  permissions: res.data.message.userDetails.permissions,
                  user: {
                    email: res.data.message.userDetails.email,
                    name: res.data.message.userDetails.name,
                  },
                })
              );
              history.push(
                `/${res.data.message.userDetails.permissions[0].link}`
              );
              // history.push("/Events");
            } else {
              firebase.auth().signOut();
              alert('Your Acount is Inactive contact Support');
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          ErrorHelper.handleErrors(err.response.data.message, true);
        });
    }
  };
  return (
    <Grid container component='main' className={classes.root}>
      <Loader isloading={isLoading} />
      <Grid item xs={false} sm={4} md={12} className={classes.image}>
        <Grid item xs={false} sm={4} md={4}>
          <Paper elevation={4} style={{ borderRadius: '8px' }}>
            <div className={classes.paper}>
              <Typography component='h1' variant='h5'>
                Sign in
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                <div style={{ textAlign: 'right', marginTop: '5px' }}>
                  <Typography
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => history.push('/forgotPassword')}
                  >
                    Forgot Password?
                  </Typography>
                </div>

                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={loginUser}
                >
                  Sign In
                </Button>
                {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
                {/* <Box mt={5}>
              <Copyright />
            </Box> */}
              </form>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}
