import React, { useState, useEffect } from "react";
import { Container, Grid, Paper } from "@material-ui/core";
import NavBar from "../../../navbar/AdminNavigation";
import { useStyles } from "../styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase";
import { Loader } from "../../../components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import { get_all_events, delete_event } from "./../../../api/index";
import Swal from "sweetalert2";

const Events = (props) => {
  const classes = useStyles();

  const [language, setLanuage] = useState("en");

  const [events, setEvents] = useState([]);
  const [selectedLangEvents, setSelectedLangEvents] = useState({});

  const [isLoading, setIsLoading] = React.useState(false);

  const getAllEvents = () => {
    setIsLoading(true);
    axios
      .get(get_all_events)
      .then((res) => {
        console.log(res.data.data, "asdasdasddsad");
        setEvents(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllEvents();
    // firebase
    //   .database()
    //   .ref("events")
    //   .on("value", (snapshot) => {
    //     let data = snapshot.val() ? snapshot.val() : {};
    //     console.log(data, "datadata");
    //     setEvents(data);
    //     setIsLoading(false);
    //   });
  }, []);

  useEffect(() => {
    if (events) {
      setSelectedLangEvents(events);
    }
  }, [events, language]);

  const handleLangChange = (event, newValue) => {
    setLanuage(newValue);
  };

  console.log(events, "eventsevents");

  const handleDelete = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${delete_event}/${row._id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Event deleted successfully.", "success");
            getAllEvents();
          })
          .catch((err) => {
            console.log(err, "faqqqaaaa");
          });
      }
    });
  };

  const renderCard = (val) => {
    console.log(val, "valval");
    let userData = JSON.parse(localStorage.getItem("user"));

    return (
      <Grid item xs={12} sm={6} md={4}>
        <Loader isloading={isLoading} />

        <Card className={classes.eventCardRoot}>
          <CardMedia
            className={classes.eventCard}
            image={val?.eventImages?.length > 0 && val.eventImages[0]}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {`${val?.eventName}`}
            </Typography>
            <Typography variant="body2" component="p">
              {"Description:"}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ marginBottom: "6px" }}
            >
              {val?.eventDescription}
            </Typography>

            <Typography variant="body2" component="p">
              {`Event Date: ${val?.eventEndDate}`}
            </Typography>

            {/* <Typography variant="body2" component="p">
              {`Created By: ${val?.createdByUser}`}
            </Typography> */}
            <Button
              onClick={(_) =>
                props.history.push({
                  pathname: `/Edit_Event/${val._id}`,
                  state: {
                    data: val,
                    // fireBaseKey: mainKey,
                  },
                })
              }
              fullWidth
              className={classes.eventEditButton}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {"Edit Event"}
            </Button>
            <Button
              onClick={() => handleDelete(val)}
              fullWidth
              className={classes.eventEditButton}
              variant="body2"
              style={{ background: "red" }}
              component="p"
            >
              {"Delete Event"}
            </Button>
            <Button
              onClick={(_) =>
                props.history.push({
                  pathname: `/Event_Detail/${val._id}`,
                  state: {
                    alllangData: val,
                    // fireBaseKey: mainKey,
                  },
                })
              }
              fullWidth
              className={classes.eventEditButton}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {"Event Details"}
            </Button>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  // let selectedEvent = Object.keys(events) || [];
  let userData = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <NavBar>
        <div>
          <Container maxWidth="xl">
            <div style={{ textAlign: "right", marginBottom: "10px" }}>
              <Button
                onClick={(_) =>
                  props.history.push({
                    pathname: "/Post_Event",
                  })
                }
                // fullWidth
                className={classes.eventPostButton}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {"Post Event"}
              </Button>
            </div>

            <Typography gutterBottom variant="h5" component="h2">
              All Events
            </Typography>
            <Grid container spacing={3}>
              {events.length > 0 &&
                events.map((val) => {
                  if (userData.userType !== "admin") {
                    // if (userData.userID === events[val]?.createdByID) {
                    return renderCard(val);
                    // }
                  } else {
                    return renderCard(val);
                  }
                })}
            </Grid>
          </Container>
        </div>
      </NavBar>
    </>
  );
};

export default Events;
