const baseUrl = "https://miautoauctions2.com/ayami/api";
// const baseUrl = 'http://192.168.0.108:4000/api';

// Auth API
export const admin_login = `${baseUrl}/adminLogin`;
export const admin_change_password = `${baseUrl}/changeAdminPassword`;
export const controller_register = `${baseUrl}/adminRegister`;
export const get_all_controller = `${baseUrl}/getControllers`;
export const update_controller_status = `${baseUrl}/updateControllerStatus`;
export const get_all_users = `${baseUrl}/getAllUsers`;
export const update_user_status = `${baseUrl}/updateUserStatus`;

// SEND EMAIL

export const send_email = `${baseUrl}/sendEmail`;

// FAQ APIS
export const create_faq = `${baseUrl}/createFaq`;
export const get_all_faq = `${baseUrl}/getFaq`;
export const update_faq = `${baseUrl}/updateFaq`;
export const delete_faq = `${baseUrl}/deleteFaq`;

// SUPPORT APIS
export const create_support = `${baseUrl}/createSupport`;
export const get_all_support = `${baseUrl}/getSupport`;

// EVENTS APIS
export const create_event = `${baseUrl}/createEvent`;
export const get_all_events = `${baseUrl}/getEvents`;
export const update_event = `${baseUrl}/updateEvent`;
export const get_event_detail_by_id = `${baseUrl}/getEventById`;
export const delete_event = `${baseUrl}/deleteEvent`;

// Tickets APIS

export const update_tickets = `${baseUrl}/updateTicket`;

// Dashboard API

export const get_dashboard_stats = `${baseUrl}/dashboardApi`;

// New Flow APIS

export const get_subscription = `${baseUrl}/getSubsciptionCharges`;
export const update_subscription = `${baseUrl}/upadteSubsciptionCharges`;

export const get_commission = `${baseUrl}/getCommission`;
export const update_commission = `${baseUrl}/upadteCommission`;

export const create_post_options = `${baseUrl}/createPostOptions`;
export const get_post_options = `${baseUrl}/getPostOptions`;
export const update_post_options = `${baseUrl}/upadtePostOptions`;
export const delete_post_options = `${baseUrl}/deletePostOption`;


// Add Post APIS
export const  create_add_post = `${baseUrl}/createPost`;
export const get_add_post = `${baseUrl}/getAllPosts`;
export const get_posts_by_id = `${baseUrl}/getPostsById`;
export const update_add_post = `${baseUrl}/updatePost`;
export const delete_add_post = `${baseUrl}/deletePost`;

//Account Details 
export const create_account_Details = `${baseUrl}/createAccountDetails`;
export const get_account_Details = `${baseUrl}/getAccountDetails`;
export const update_account_Details = `${baseUrl}/upadteAccountDetails`;
export const payCommission=`${baseUrl}/payCommission`;



