import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import { Container, Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../styles";
import axios from "axios";
import firebase from "firebase";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import { Loader, ErrorHelper, successHelper } from "../../../components";
import { Delete } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { English } from "../LanguageComponents";
import CreateEventDialog from "./CreateEventDialog";
import { create_event } from "../../../api";

const PostEvent = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOPenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("Create");
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [vehicleDetailsEnglish, setVehicleDetailsEnglish] = useState({
    registeredCity: "",
    vehicleName: "",
    registrationNumber: "",
    chasisNo: "",
    color: "",
    milage: "",
    price: "",
    description: "",
  });
  const [eventDetailEnglish, setEventDetailEnglish] = useState({
    eventName: "",
    eventDesscription: "",
    eventTicketPrice: "",
  });

  const [selectedDatePost, setSelectedDatePost] = React.useState(new Date());
  const [selectedDateEventPost, setSelectedDateEventPost] = React.useState(
    new Date()
  );
  const [lastRegistrationDatePost, setLastRegistarionDatePost] = React.useState(
    new Date()
  );
  const [selectedCityPostEnglish, setSelectedCityPostEnglish] =
    React.useState("");
  const [selectedCompanyPost, setSelectedCompanyPost] = React.useState("");
  const [eventTicketImage, setEventTicketImage] = React.useState("");

  const [vehicleImage, setVehicleImages] = useState([]);
  const [videoFile, setVideoFile] = useState("");
  const [language, setLanuage] = useState("en");
  const [eventFirebaseKey, setEventFirebaseKey] = useState(null);
  const [englishData, setEnglishData] = useState(null);
  const [eventPrizes, setEventPrizes] = useState([]);
  const [carArray, setCarArray] = useState([]);

  const handleDateChange = (date) => {
    setSelectedDatePost(date);
  };
  const handleDateChangeEvent = (date) => {
    setSelectedDateEventPost(date);
  };
  const handleEndDate = (date) => {
    setLastRegistarionDatePost(date);
  };

  const handleCityChange = (event) => {
    setSelectedCityPostEnglish(event.target.value);
  };
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  useEffect(() => {
    let eventFirebaseKeyUSe = uuidv4();
    setEventFirebaseKey(`${eventFirebaseKeyUSe}`);
  }, []);

  console.log(eventPrizes, "eventPrizeseventPrizes");

  const handleCreate = () => {
    // if (eventDetailEnglish.eventName === "") {
    //   ErrorHelper.handleErrors(
    //     "Please fill complete info in all langugaes",
    //     true
    //   );
    // } else if (eventDetailEnglish.eventDesscription === "") {
    //   ErrorHelper.handleErrors(
    //     "Please fill complete info in all langugaes",
    //     true
    //   );
    // }
    if (vehicleImage[0] == null) {
      ErrorHelper.handleErrors("Image is Required", true);
    } else if (eventPrizes.length < 1) {
      ErrorHelper.handleErrors("Please add event prize", true);
    } else if (eventPrizes.length < 1) {
      ErrorHelper.handleErrors(
        "Please add atleast one prize for this event",
        true
      );
    } else if (eventTicketImage === "") {
      ErrorHelper.handleErrors("Event Ticket Image is required", true);
    } else if (eventDetailEnglish.eventName === "") {
      ErrorHelper.handleErrors("Event Name is required", true);
    } else if (eventDetailEnglish.eventTicketPrice === "") {
      ErrorHelper.handleErrors("Event Ticket Price is required", true);
    } else {
      let userData = JSON.parse(localStorage.getItem("user"));

      let paramsEng = {
        eventId: eventFirebaseKey,
        createdByID: userData?.userID,
        ...eventDetailEnglish,
        lastRegistrationDate: moment(lastRegistrationDatePost).format(
          "DD-MM-Y"
        ),
        eventDate: moment(selectedDateEventPost).format("DD-MM-Y"),
        videoFile: videoFile,
        eventPrizes: eventPrizes,
        language: "en",
        createdByUser: userData.userType === "admin" ? "Admin" : "Controller",
        winner: "null",
        vehicleImage,
        eventTicketImage,
      };

      let paramssssss = {
        eventName: eventDetailEnglish.eventName,
        eventDescription: eventDetailEnglish.eventDesscription,
        eventTicketPrice: JSON.parse(eventDetailEnglish.eventTicketPrice),
        eventLastRegistrationDate: moment(lastRegistrationDatePost).format(
          "YYYY-MM-DD"
        ),
        eventEndDate: moment(selectedDateEventPost).format("YYYY-MM-DD"),
        eventPrizes,
        eventImages: vehicleImage,
        eventTicketImage,
      };

      console.log(paramssssss, "paramssssssparamssssss");
      // return;

      // firebase
      //   .database()
      //   .ref(`events`)
      //   .push({ ...paramsEng })

      axios
        .post(create_event, paramssssss)
        .then((res) => {
          successHelper.handleSuccess("Event Created Succesfully", true);
          setTimeout(() => {
            window.history.back();
          }, 2000);

          setIsLoading(false);
          setEventDetailEnglish({
            eventName: "",
            prizeName: "",
            prizeDescription: "",
            eventDesscription: "",
          });
          setVehicleDetailsEnglish({
            vehicleName: "",
            registrationNumber: "",
            color: "",
            milage: "",
            price: "",
            description: "",
          });
        })
        .catch((err) => {
          setIsLoading(false);

          console.log(err, "errrr");
        });
    }
  };

  const onChangePicture = async (e, index) => {
    const sizLimitInBytes = 10000000;
    if (e.target.files.length <= 0) return;
    if (e.target.files[0].size < sizLimitInBytes) {
      setIsLoading(true);
      const form = new FormData();
      form.append("image", e.target.files[0]);
      // form.append("upload_preset", "awfemeq5");
      try {
        let res = await axios.post("https://amberstore.pk/upload.php", form);
        if (res) {
          if (res.data.resource_type == "video") {
            setVideoFile(res.data.url);
            setIsLoading(false);
          } else {
            let imgs = [...vehicleImage];
            imgs.push(res.data.url);
            setVehicleImages(imgs);
            console.log(res, "ressssssss");
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error, "ERRRRR=>>>>>");
        setIsLoading(false);
      }
    } else {
      alert("File Size Limit Exceed, Please Select File Less Than 10 MB");
    }
  };
  const onUploadTicket = async (e, index) => {
    const sizLimitInBytes = 10000000;

    if (e.target.files[0].size < sizLimitInBytes) {
      setIsLoading(true);
      const form = new FormData();
      form.append("image", e.target.files[0]);
      // form.append("upload_preset", "awfemeq5");
      try {
        let res = await axios.post("https://amberstore.pk/upload.php", form);
        if (res) {
          setEventTicketImage(res.data.url);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error, "ERRRRR=>>>>>");
        setIsLoading(false);
      }
    } else {
      alert("File Size Limit Exceed, Please Select File Less Than 10 MB");
    }
  };

  const refrences = useRef();
  const ticketRef = useRef();

  let handleRef = () => {
    refrences.current.click();
  };
  let handleTicketRef = () => {
    ticketRef.current.click();
  };

  const renderImageCard = (value, index) => {
    let handleDelte = () => {
      let img = [...vehicleImage];
      img.splice(index, 1);
      setVehicleImages(img);
    };

    return (
      <Card className={classes.eventCardRoot}>
        <CardMedia className={classes.eventCard} image={value} />
        <div
          onClick={handleDelte}
          style={{
            cursor: "pointer",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            paddingTop: "3px",
            paddingTop: "3px",
            color: "#fff",
            backgroundColor: "#ec5a4f",
            fontSize: "16px",
          }}
        >
          Delete
          <Delete
            style={{ color: "#fff", fontSize: "18px", marginTop: "1px" }}
          />
        </div>
      </Card>
    );
  };
  const handleLangChange = (event, newValue) => {
    // alert('Please save data in english before changing the language')
    setLanuage(newValue);
  };

  console.log(eventPrizes, "eventPrizeseventPrizes");

  return (
    <NavBar>
      <CreateEventDialog
        setEventPrizes={setEventPrizes}
        eventPrizes={eventPrizes}
        openModal={isOpenModal}
        setOpen={setIsOPenModal}
        type={dialogTitle}
        data={eventPrizes}
        setIsOpenSnack={false}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
      />

      <Container maxWidth="xl">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <Typography gutterBottom variant="h6" component="h2">
            {/* Vehicle Details */}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "15px",
              justifyContent: "space-around",
              width: "45%",
            }}
          >
            <Button
              onClick={(_) => {
                setDialogTitle("Create");
                setIsOPenModal(true);
              }}
              disabled={eventPrizes.length === 3}
              style={{
                textAlign: "center",
                marginTop: "0px",
                fontSize: "12px",
                height: "30px",
              }}
              variant="contained"
              color="primary"
            >
              Add Prize
            </Button>
            {/* <Button
              disabled={videoFile}
              onClick={handleRef}
              style={{ textAlign: "center", fontSize: "12px", height: "30px" }}
              variant="contained"
              color="primary"
            >
              Add Video
            </Button> */}
            <Button
              onClick={handleRef}
              style={{
                textAlign: "center",
                fontSize: "12px",
                height: "30px",
              }}
              variant="contained"
              color="primary"
            >
              Add Image
            </Button>
            <Button
              onClick={handleTicketRef}
              style={{
                textAlign: "center",
                fontSize: "12px",
                height: "30px",
              }}
              variant="contained"
              color="primary"
            >
              Upload Ticket Image
            </Button>
          </div>
          <input
            type="file"
            hidden
            ref={refrences}
            onChange={(e) => onChangePicture(e)}
          />
          <input
            type="file"
            hidden
            ref={ticketRef}
            onChange={(e) => onUploadTicket(e)}
          />
        </div>
        {console.log(videoFile, "videoFilevideoFile")}
        {videoFile ? (
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom variant="h6" component="h2">
                Video
              </Typography>
              <Card className={classes.eventCardRootVideo}>
                <CardMedia
                  className={classes.eventCardVdeo}
                  src={videoFile}
                  component="video"
                  controls
                />
                <div
                  onClick={(_) => setVideoFile(null)}
                  style={{
                    cursor: "pointer",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "3px",
                    paddingTop: "3px",
                    color: "#fff",
                    backgroundColor: "#ec5a4f",
                    fontSize: "16px",
                  }}
                >
                  Delete
                  <Delete
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      marginTop: "1px",
                    }}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        ) : null}
        {eventTicketImage !== "" ? (
          <>
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              style={{ marginTop: "1%", marginBottom: "-5px" }}
            >
              Event Ticket Image
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Card className={classes.eventCardRoot}>
                  <CardMedia
                    className={classes.eventCard}
                    image={eventTicketImage}
                  />
                  <div
                    onClick={(_) => setEventTicketImage("")}
                    style={{
                      cursor: "pointer",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      paddingTop: "3px",
                      paddingTop: "3px",
                      color: "#fff",
                      backgroundColor: "#ec5a4f",
                      fontSize: "16px",
                    }}
                  >
                    Delete
                    <Delete
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        marginTop: "1px",
                      }}
                    />
                  </div>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : null}
        {vehicleImage.length > 0 && (
          <Typography
            gutterBottom
            variant="h6"
            component="h2"
            style={{ marginTop: "1%", marginBottom: "-5px" }}
          >
            Images
          </Typography>
        )}
        <Grid container spacing={2}>
          {vehicleImage.map((val, index) => {
            return (
              <Grid item xs={12} sm={3}>
                {renderImageCard(val, index)}
              </Grid>
            );
          })}
        </Grid>

        <English
          uploadedVideos={videoFile}
          uploadVehicleImages={vehicleImage}
          eventFirebaseKey={eventFirebaseKey}
          type={"Create"}
          setDataForPost={setEnglishData}
          dataforpost={englishData}
          language={language}
          setEventDetailPost={setEventDetailEnglish}
          setVehicleDetailsPost={setVehicleDetailsEnglish}
          vehicleDetailsPost={vehicleDetailsEnglish}
          eventDetailPost={eventDetailEnglish}
          setSelectedCityPost={setSelectedCityPostEnglish}
          selectedCityPost={selectedCityPostEnglish}
          lastRegistrationDatePost={lastRegistrationDatePost}
          setLastRegistarionDatePost={setLastRegistarionDatePost}
          setSelectedDateEventPost={setSelectedDateEventPost}
          selectedDateEventPost={selectedDateEventPost}
          selectedDatePost={selectedDatePost}
          setSelectedDatePost={setSelectedDatePost}
          setSelectedCompanyPost={setSelectedCompanyPost}
          selectedCompanyPost={selectedCompanyPost}
        />

        <Loader isloading={isLoading} />

        <Typography gutterBottom variant="h6" component="h2">
          Event Prizes
        </Typography>

        <Grid container spacing={4}>
          {eventPrizes.map((prize, index) => {
            console.log(prize, "prize");
            return (
              <Grid item xs={12} md={4}>
                <Card className={classes.eventCardRoot}>
                  <CardContent>
                    <Typography gutterBottom>
                      Vehicle Name: {prize.Vehicle_Name}
                    </Typography>
                    <Typography gutterBottom>
                      Vehicle Number: {prize.Registration_Number}
                    </Typography>
                    <Typography gutterBottom>Price: {prize.price}</Typography>
                    <Typography gutterBottom>
                      Description: {prize.description}
                    </Typography>
                    <Typography gutterBottom>
                      Color: {prize.Vehicle_Color}
                    </Typography>
                    <Typography gutterBottom>
                      City: {prize.Registration_City}
                    </Typography>

                    <Button
                      onClick={() => {
                        setIsOPenModal(true);
                        setSelectedIndex(index);
                        setDialogTitle("Edit");
                      }}
                      fullWidth
                      className={classes.eventEditButton}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {"Edit"}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <Button
              onClick={handleCreate}
              style={{ textAlign: "center", marginTop: "15px" }}
              variant="contained"
              color="primary"
              fullWidth
            >
              {"Post"}
            </Button>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      </Container>
    </NavBar>
  );
};

export default PostEvent;
