import React, { useState, useEffect } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  TextField,
  Typography,
  Switch,
  InputLabel,
  FormControl,
  Select,
  MenuItem

} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../styles";
import firebase from "firebase"
import { Loader, successHelper, SuccesSnack } from "../../../components";
import { DateRangePicker } from "react-dates";
import moment from 'moment'
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../react_dates_overrides.css";




const MannualPayments = () => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [userData, setUserData] = useState({ name: '', email: '', password: '', status: true })
  const [allUsers, setAllUsers] = useState([])
  const [allPayments, setAllPayments] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState(null)
  const [accountNumber, setAccountNumber] = React.useState('')
  const [accountTitle, setAccountTitle] = React.useState('')
  const [isOpenSnack, setIsOpenSnack] = React.useState(false)
  const [dateRange, setdateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const { startDate, endDate } = dateRange;
  const [focus, setFocus] = useState(null);

  const tableHeading = ['ID', 'User Name', 'Account Number', "Receipt Image"]

  useEffect(() => {
    setIsLoading(true)
    firebase.database().ref("adminAccountDetails").on("value", snapshot => {
      let data = snapshot.val() ? snapshot.val() : {}
      // let userData = Object.values(data)
      // setAllUsers(userData)
      // setIsLoading(false)
      if (data) {
        setAccountNumber(data.number)
        setAccountTitle(data.title)
      }
    })

    firebase.database().ref("ManualEntry").on("value", snapshot => {
      let data = snapshot.val() ? snapshot.val() : {}
      let paymentsData = Object.keys(data)
      let tempArr = []
      paymentsData.map(val => {
        let items = Object.values(data[val])
        tempArr = [...tempArr, ...items]

      })
      setAllPayments(tempArr)
      setFilteredData(tempArr)

      setIsLoading(false)

    })

  }, [])
  const paymentsTable = () => {
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleChangeSwitch = (key) => {
      // firebase.database().ref(`controllers/${key}`).update({
      //   ...allControllers[key],
      //   status: !allControllers[key].status
      // }).then(res => {
      //   alert('Updated Succesfully')
      // }).catch(err => {

      // })
    }

    // for firebaseIntegration
    // let users = Object.keys(allUsers)
    let users = [
      { date: '12/12/2021', amount: '45$', paymentType: 'Stripe', user: 'Test Event', },
      { date: '10/12/2021', amount: '850$', paymentType: 'Stripe', user: 'Test 2' }
    ]
    return (
      <Paper>
        <TableContainer component={Paper} style={{ padding: "20px" }}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tableHeading.map((heading, index) => {
                  return (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        padding: "6px",
                      }}
                    >
                      {heading}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 && filteredData.map((row, ind) => (
                <TableRow key={ind} className={classes.tableRow}>
                  <TableCell align="center" className={classes.rowName}>
                    {ind + 1}
                  </TableCell>
                  <TableCell align="center" className={classes.rowName}>
                    {row?.user?.name}
                  </TableCell>
                  <TableCell align="center" className={classes.rowName}>
                    {row?.accountNumber}
                  </TableCell>
                  <TableCell align="center" className={classes.rowName}>
                    {row?.image ? <a target='_blank' href={row?.image}>View Image</a> : '-'}
                  </TableCell>

                  {/* <TableCell align="center" className={classes.rowName}>
                    {row.user.name}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    )
  }

  const handleOnDateChange = (startDate, endDate) => {
    let start = startDate.startDate;
    let end = startDate.endDate;
    setdateRange({
      startDate: start,
      endDate: end,
    });
  };
  const handleSaveAccount = () => {
    firebase.database().ref("adminAccountDetails").set(
      { title: accountTitle, number: accountNumber }
    ).then(res => {
      // successHelper.handleSuccess('Event Created  Succesfully', true);
      setIsLoading(false)
      setIsOpenSnack(true)

    }).catch((err) => {
      setIsLoading(false)

      console.log(err, 'errrr')
    })
    // let filtered = []
    // setIsLoading(true)
    // events.map(val => {
    //   let date = new Date(val.eventDate)
    //   let diff = moment(`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`).isBetween(dateRange.startDate, dateRange.endDate)
    //   if (diff) {
    //     filtered.push(val)
    //   }
    // })
    // setTimeout(() => {
    //   setIsLoading(false)
    // }, 2000);

  }
  const handleSelectedUser = (e) => {
    setSelectedUser(e.target.value)
    if (e.target.value == 'All') {
      setFilteredData(allPayments)
    } else {
      let selectedUserData = []
      allPayments.map(val => {
        if (val.user.name == e.target.value) {
          selectedUserData.push(val)
        }
      })
      setFilteredData(selectedUserData)
    }
  }


  return (
    <NavBar>
      <Container maxWidth="xl">
        <SuccesSnack open={isOpenSnack} message={"Details Saved Succesfully"} closeSnack={setIsOpenSnack} />

        <Paper className={classes.paperRoot}>
          <Grid container spacing={2} alignItems="flex-end">
            {/* <Grid item xs={12} sm={8} md={4}>
      
              <DateRangePicker
                isOutsideRange={() => false}
                startDatePlaceholderText="Start Date"
                startDate={startDate}
                onDatesChange={handleOnDateChange}
                endDatePlaceholderText="End Date"
                endDate={endDate}
                numberOfMonths={2}
                displayFormat="MMM D Y"
                showClearDates={true}
                focusedInput={focus}
                onFocusChange={(focus) => setFocus(focus)}
                startDateId="startDateMookh"
                endDateId="endDateMookh"
                minimumNights={0}
                small

              />
            </Grid> */}
            <Grid item xs={12} sm={4} md={3}>

              <TextField
                variant="outlined"
                placeholder="Title"
                label="Account Title"
                size="small"
                fullWidth
                value={accountTitle}
                onChange={e => setAccountTitle(e.target.value)}
              />

            </Grid>
            <Grid item xs={12} sm={4} md={3}>


              <TextField
                variant="outlined"
                placeholder="Number"
                label="Account Number"
                size="small"
                fullWidth
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.createBtn}
                  style={{ textTransform: "none", padding: '8px 40px', width: '25px' }}
                  onClick={handleSaveAccount}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={2} style={{ marginTop: '5px', marginBottom: '3%' }}>
          <Loader isloading={isLoading} />
        </Grid>
        <div>
          {paymentsTable()}
        </div>
      </Container>
    </NavBar>
  );
};

export default MannualPayments;
