import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TextField,
  Grid,
  Button,
  Select,
  FormControl,
  MenuItem,
  Paper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../styles";
import DateFnsUtils from "@date-io/date-fns";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import firebase from "firebase";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import AddIcon from "@material-ui/icons/Add";
import { Loader, successHelper, ErrorHelper } from "../../../components";
import { Delete } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import AppBar from "@material-ui/core/AppBar";
import { update_event } from "../../../api";

const English = ({
  uploadedVideos,
  uploadVehicleImages,
  eventFirebaseKey,
  type,
  eventData,
  setVehicleImageEdit,
  setVideoEdit,
  setDataForPost,
  dataforpost,
  setSelectedCityPost,
  selectedCityPost,
  lastRegistrationDatePost,
  setLastRegistarionDatePost,
  setSelectedDateEventPost,
  selectedDateEventPost,
  selectedDatePost,
  setSelectedDatePost,
  setEventDetailPost,
  setVehicleDetailsPost,
  eventDetailPost,
  vehicleDetailsPost,
  selectedCompanyPost,
  setSelectedCompanyPost,
  eventPrizes,
  eventDataJp,
  eventDataFr,
  eventTicketImage,
  eventDetail,
  setEventDetail,
}) => {
  console.log(eventData, type, "dataforpostdataforpostdataforpost");
  console.log(eventPrizes, "eventPrizeseventPrizeseventPrizes");

  const classes = useStyles();
  const [setSelectedDate] = React.useState(new Date());
  const [selectedDateEvent, setSelectedDateEvent] = React.useState(new Date());
  const [lastRegistrationDate, setLastRegistarionDate] = React.useState(
    new Date()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [setSelectedCity] = React.useState("");

  const [company, setCompany] = React.useState([
    { name: "Suzuki", id: 0 },
    { name: "Toyota", id: 1 },
    { name: "Honda", id: 2 },
  ]);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [vehicleDetails, setVehicleDetails] = useState({
    vehicleName: "",
    registrationNumber: "",
    color: "",
    milage: "",
    price: "",
    description: "",
  });

  const [vehicleImage, setVehicleImages] = useState([]);
  const [language, setLanuage] = useState("en");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const [carArray, setCarArray] = useState([
    { name: "Toyota" },
    { name: "Volkswagen" },
    { name: "Rolls-Royce" },
    { name: "Porsche" },
    { name: "Nissan" },
    { name: "Mercedes" },
    { name: "Honda" },
  ]);

  useEffect(() => {
    // setIsLoading(true);
    var options = {
      method: "GET",
      url: "https://private-anon-f35f62ca96-carsapi1.apiary-mock.com/manufacturers",
      headers: {
        "x-rapidapi-host": "cis-automotive.p.rapidapi.com",
        "x-rapidapi-key": "b4828b2f2bmsh4af44d2999f44a5p109fb4jsn594365bc0fea",
      },
    };
    axios
      .request(options)
      .then(function (response) {
        let arr = [...response.data, { name: "Suzuki" }];
        console.log(arr, "ressssspinsee");
        setCarArray(arr);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  let dateFormatter = (value) => {
    let selectedEventFormatted = new Date();
    let registrationDate = value?.split("-");
    if (registrationDate) {
      console.log(registrationDate, "registrationDate");
      selectedEventFormatted.setDate(registrationDate[2]);
      selectedEventFormatted.setMonth(registrationDate[1] - 1);
      selectedEventFormatted.setFullYear(registrationDate[0]);
      return selectedEventFormatted;
    }
  };

  useEffect(() => {
    if (type === "Update") {
      console.log(eventData, "eventDataeventData");

      if (eventData) {
        console.log(
          dateFormatter(eventData?.eventEndDate, "alskdalkdjsadkjsad")
        );
        setSelectedDateEventPost(dateFormatter(eventData?.eventEndDate));
        setLastRegistarionDatePost(
          dateFormatter(eventData?.eventLastRegistrationDate)
        );
        setEventDetail({
          eventName: eventData?.eventName,
          eventDesscription: eventData.eventDescription,
          eventTicketPrice: eventData.eventTicketPrice,
        });
        setVideoEdit(eventData.videoFile ? eventData.videoFile : "");

        let images = [...uploadVehicleImages];
        eventData.eventImages?.map((value, index) => {
          images[index] = value;
        });
        setVehicleImageEdit(images);
      }
    }
  }, [eventData, type]);
  const handleCreate = () => {
    let { eventName, eventDesscription, eventTicketPrice } = eventDetail;

    if (uploadVehicleImages[0] == null) {
      ErrorHelper.handleErrors("Image is Required", true);
    } else if (eventPrizes.length < 1) {
      ErrorHelper.handleErrors(
        "Please add atleast one prize for this event",
        true
      );
    } else if (eventTicketImage === "") {
      ErrorHelper.handleErrors("Event Ticket Image is required", true);
    } else if (eventName === "") {
      ErrorHelper.handleErrors("Event Name is required", true);
    } else if (eventDesscription === "") {
      ErrorHelper.handleErrors("Event Ticket Price is required", true);
    } else {
      let userData = JSON.parse(localStorage.getItem("user"));
      setIsLoading(true);

      console.log(eventData, "eventData");
      if (type === "Update" && eventData) {
        let params = {
          ...eventDetail,
          eventId: eventData.eventId,
          createdByID: userData.userID,
          createdByUser: userData.userType == "admin" ? "Admin" : "Controller",
          lastRegistrationDate: moment(lastRegistrationDatePost).format(
            "DD-MM-Y"
          ),
          videoFile: uploadedVideos,
          eventDate: moment(selectedDateEventPost).format("DD-MM-Y"),
          eventPrizes,
          eventTicketImage,
          vehicleImage: uploadVehicleImages,
        };

        let paramssssss = {
          eventName: eventDetail.eventName,
          eventDescription: eventDetail.eventDesscription,
          eventTicketPrice: JSON.parse(eventDetail.eventTicketPrice),
          eventLastRegistrationDate: moment(lastRegistrationDatePost).format(
            "YYYY-MM-DD"
          ),
          eventEndDate: moment(selectedDateEventPost).format("YYYY-MM-DD"),
          eventPrizes,
          eventImages: uploadVehicleImages,
          eventTicketImage,
        };

        console.log(paramssssss, "paramssssssparamssssss");

        // firebase
        //   .database()
        //   .ref(`events/${eventFirebaseKey}`)
        //   .update({ ...params })

        axios
          .put(`${update_event}/${eventData._id}`, paramssssss)
          .then((res) => {
            // setTimeout(() => {
            //   window.history.back();
            // }, 2000);
            successHelper.handleSuccess("Event Updated  Succesfully", true);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err, "errrr");
            setIsLoading(false);
          });
      } else {
        let params = {
          eventId: eventFirebaseKey,
          createdByID: userData?.userID,
          ...eventDetail,
          lastRegistrationDate: moment(lastRegistrationDate).format("DD-MM-Y"),
          eventDate: moment(selectedDateEvent).format("DD-MM-Y"),
          videoFile: uploadedVideos,
          language: language,
          createdByUser: userData.userType === "admin" ? "Admin" : "Controller",
          winner: "null",
        };

        setDataForPost(params);
      }
    }
  };

  return (
    <>
      <div>
        <Typography gutterBottom variant="h6" component="h2">
          Event Details
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
          {type == "Create" ? (
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                placeholder="Event Name"
                label="Event Name"
                size="small"
                fullWidth
                value={eventDetailPost.eventName}
                onChange={(e) =>
                  setEventDetailPost({
                    ...eventDetailPost,
                    eventName: e.target.value,
                  })
                }
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                placeholder="Event Name"
                label="Event Name"
                size="small"
                fullWidth
                value={eventDetail.eventName}
                onChange={(e) =>
                  setEventDetail({ ...eventDetail, eventName: e.target.value })
                }
              />
            </Grid>
          )}
          {type == "Create" ? (
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                placeholder="Event Ticket Price"
                label="Event Ticket Price"
                size="small"
                fullWidth
                type="number"
                value={eventDetailPost.eventTicketPrice}
                onChange={(e) =>
                  setEventDetailPost({
                    ...eventDetailPost,
                    eventTicketPrice: e.target.value,
                  })
                }
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                placeholder="Event Ticket Price"
                label="Event Ticket Price"
                size="small"
                fullWidth
                type="number"
                value={eventDetail.eventTicketPrice}
                onChange={(e) =>
                  setEventDetail({
                    ...eventDetail,
                    eventTicketPrice: e.target.value,
                  })
                }
              />
            </Grid>
          )}
          {/* {type == 'Create' ?
            <Grid style={{ marginTop: '-10px' }} item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  openTo="year"
                  views={["year", "month", "date"]}
                  label="Event End Date"
                  value={selectedDateEventPost}
                  onChange={setSelectedDateEventPost}
                  variant="outlined"
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            :
            <Grid style={{ marginTop: '-10px' }} item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  openTo="year"
                  views={["year", "month", "date"]}
                  label="Event End Date"
                  value={selectedDateEvent}
                  onChange={handleDateChangeEvent}
                  variant="outlined"
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
          } */}
          <Grid style={{ marginTop: "-10px" }} item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                openTo="year"
                views={["year", "month", "date"]}
                label="Event End Date"
                value={selectedDateEventPost}
                onChange={setSelectedDateEventPost}
                minDate={new Date()}
                format="dd/MM/yyyy"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid style={{ marginTop: "-10px" }} item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                openTo="year"
                views={["year", "month", "date"]}
                label="Last Registration Date"
                value={lastRegistrationDatePost}
                onChange={setLastRegistarionDatePost}
                minDate={new Date()}
                maxDate={selectedDateEventPost}
                format="dd/MM/yyyy"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* {type == 'Create' ?
            <Grid style={{ marginTop: '-10px' }} item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  openTo="year"
                  views={["year", "month", "date"]}
                  label="Last Registration Date"
                  value={lastRegistrationDatePost}
                  onChange={setLastRegistarionDatePost}
                  variant="outlined"
                  minDate={new Date()}
                  maxDate={selectedDateEvent}
                  format="dd/MM/yyyy"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            :
            <Grid style={{ marginTop: '-10px' }} item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  openTo="year"
                  views={["year", "month", "date"]}
                  label="Last Registration Date"
                  value={lastRegistrationDate}
                  onChange={handleEndDate}
                  variant="outlined"
                  minDate={new Date()}
                  maxDate={selectedDateEvent}
                  format="dd/MM/yyyy"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
          } */}

          {type == "Create" ? (
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Event Description"
                label="Event Description"
                size="small"
                fullWidth
                multiline={true}
                minRows={8}
                value={eventDetailPost.eventDesscription}
                onChange={(e) =>
                  setEventDetailPost({
                    ...eventDetailPost,
                    eventDesscription: e.target.value,
                  })
                }
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Event Description"
                label="Event Description"
                size="small"
                fullWidth
                multiline={true}
                minRows={8}
                value={eventDetail.eventDesscription}
                onChange={(e) =>
                  setEventDetail({
                    ...eventDetail,
                    eventDesscription: e.target.value,
                  })
                }
              />
            </Grid>
          )}
        </Grid>
        <Loader isloading={isLoading} />

        {eventData && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}>
              <Button
                onClick={handleCreate}
                style={{ textAlign: "center", marginTop: "15px" }}
                variant="contained"
                color="primary"
                fullWidth
              >
                {"Update"}
              </Button>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default English;
