import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  TextField,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import firebase from "firebase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { v4 as uuidv4 } from "uuid";
import { useStyles } from "../styles";
import DateFnsUtils from "@date-io/date-fns";
import { Loader, successHelper, ErrorHelper } from "../../../components";
import CustomizedSnackbars from "../../../components/Alert/ErrorSnack";
import SuccesSnack from "../../../components/Alert/SuccesSnack";
import moment from "moment";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditDialog({
  selectedIndex,
  openModal,
  setOpen,
  data,
  eventPrizes,
  setEventPrizes,
  setSnackMessage,
  type,
  setIsOpenSnack,
  modalTitle,
  rowData,
  selectedRowIndex,
  setIsOPenSnackError,
  setSelectedIndex,
}) {
  const classes = useStyles();

  const [frenchIndex, setFrenchIndex] = useState(null);
  const [japneseIndex, setJapneseIndex] = useState(null);
  const [carArray, setCarArray] = useState([
    { name: "Toyota" },
    { name: "Volkswagen" },
    { name: "Rolls-Royce" },
    { name: "Porsche" },
    { name: "Nissan" },
    { name: "Mercedes" },
    { name: "Honda" },
  ]);
  const [selectedCityPost, setSelectedCityPost] = React.useState("");
  const [selectedCompanyPost, setSelectedCompanyPost] = React.useState("");
  const [selectedDatePost, setSelectedDatePost] = React.useState(new Date());
  const [vehicleDetailsEnglish, setVehicleDetailsEnglish] = useState({
    registeredCity: "",
    vehicleName: "",
    registrationNumber: "",
    color: "",
    milage: "",
    price: "",
    description: "",
    chasisNo: "",
  });
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  console.log(type, "eventPrizeseventPrizes");

  let dateFormatter = (value) => {
    let selectedEventFormatted = new Date();
    let registrationDate = value?.split("-");
    console.log(registrationDate, "registrationDateregistrationDate");
    selectedEventFormatted.setDate(registrationDate[0]);
    selectedEventFormatted.setMonth(registrationDate[1]);
    selectedEventFormatted.setFullYear(registrationDate[2]);
    return selectedEventFormatted;
  };

  useEffect(() => {
    if (type === "Edit") {
      if (eventPrizes) {
        let format = moment(eventPrizes[0]?.regVechicleDate).format(
          "DD-MM-YYYY"
        );
        console.log(format, "formatformatformat");
        setSelectedDatePost(dateFormatter(format));
      }
    }
  }, [eventPrizes, type]);

  useEffect(() => {
    console.log(selectedIndex, "selectedIndex");

    if (selectedIndex !== null) {
      let data = eventPrizes[selectedIndex];
      console.log(data, "asdkjsadhsakjda");
      setVehicleDetailsEnglish({
        registeredCity: data.Registration_City,
        vehicleName: data.Vehicle_Name,
        registrationNumber: data.Registration_Number,
        color: data.Vehicle_Color,
        milage: data.mileage,
        price: data.price,
        description: data.description,
        chasisNo: data.Chasis_Number,
      });
      setSelectedCompanyPost(data.Selected_Company);
      // setVehicleDetailsFrench(data.fr);
      // setVehicleDetailsJapnese(data.jp);
    }
  }, [eventPrizes, selectedIndex]);

  console.log(japneseIndex, "janeseIndex");
  console.log(frenchIndex, "frenchIndex");

  const handleClose = () => {
    setOpen(false);
    // setModalTitle(null)
    setSelectedIndex(null);

    setVehicleDetailsEnglish({
      registeredCity: "",
      vehicleName: "",
      registrationNumber: "",
      color: "",
      milage: "",
      price: "",
      description: "",
    });
  };

  const handleCreate = () => {
    if (vehicleDetailsEnglish.vehicleName === "") {
      setIsError(true);
    } else if (vehicleDetailsEnglish.registrationNumber === "") {
      setIsError(true);
    } else if (vehicleDetailsEnglish.color === "") {
      setIsError(true);
    } else if (vehicleDetailsEnglish.milage === "") {
      setIsError(true);
    } else if (vehicleDetailsEnglish.price === "") {
      setIsError(true);
    } else if (vehicleDetailsEnglish.description === "") {
      setIsError(true);
    } else {
      let userData = JSON.parse(localStorage.getItem("user"));
      if (type === "Create") {
        let prizes = [...eventPrizes];

        // prizes.push({
        //   ...vehicleDetailsEnglish,
        //   regVechicleDate: selectedDatePost,
        //   company: selectedCompanyPost,
        //   isWon: false,
        //   prizeNo:
        //     eventPrizes.length === 0
        //       ? "1st Prize"
        //       : eventPrizes.length === 1
        //       ? "2nd Prize"
        //       : "3rd Prize",
        // });

        prizes.push({
          Registration_City: vehicleDetailsEnglish.registeredCity,
          Selected_Company: selectedCompanyPost,
          Vehicle_Name: vehicleDetailsEnglish.vehicleName,
          Registration_Number: vehicleDetailsEnglish.registrationNumber,
          Chasis_Number: vehicleDetailsEnglish.chasisNo,
          Car_Registration_Year: selectedDatePost,
          Vehicle_Color: vehicleDetailsEnglish.color,
          mileage: vehicleDetailsEnglish.milage,
          price: vehicleDetailsEnglish.price,
          description: vehicleDetailsEnglish.description,
        });

        setEventPrizes(prizes);
        setIsSuccess(true);
      } else {
        let prizes = [...eventPrizes];

        if (selectedIndex) {
          prizes[selectedIndex] = {
            Registration_City: vehicleDetailsEnglish.registeredCity,
            Selected_Company: selectedCompanyPost,
            Vehicle_Name: vehicleDetailsEnglish.vehicleName,
            Registration_Number: vehicleDetailsEnglish.registrationNumber,
            Chasis_Number: vehicleDetailsEnglish.chasisNo,
            Car_Registration_Year: selectedDatePost,
            Vehicle_Color: vehicleDetailsEnglish.color,
            mileage: vehicleDetailsEnglish.milage,
            price: vehicleDetailsEnglish.price,
            description: vehicleDetailsEnglish.description,
          };
        } else {
          prizes.push({
            Registration_City: vehicleDetailsEnglish.registeredCity,
            Selected_Company: selectedCompanyPost,
            Vehicle_Name: vehicleDetailsEnglish.vehicleName,
            Registration_Number: vehicleDetailsEnglish.registrationNumber,
            Chasis_Number: vehicleDetailsEnglish.chasisNo,
            Car_Registration_Year: selectedDatePost,
            Vehicle_Color: vehicleDetailsEnglish.color,
            mileage: vehicleDetailsEnglish.milage,
            price: vehicleDetailsEnglish.price,
            description: vehicleDetailsEnglish.description,
          });
        }
        // prizes[selectedIndex] = {
        //   ...vehicleDetailsEnglish,
        //   regVechicleDate: selectedDatePost,
        // };
        setEventPrizes(prizes);
        setIsSuccess(true);
      }
      handleClose();
    }
  };

  const renderEnglish = () => {
    return (
      <>
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          style={{ marginTop: "1%" }}
        >
          <div>
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={4}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  City
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={vehicleDetailsEnglish.registeredCity}
                  onChange={(e) =>
                    setVehicleDetailsEnglish({
                      ...vehicleDetailsEnglish,
                      registeredCity: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  Select Company
                </InputLabel>
                <FormControl size="small" className={classes.userRoleStyle}>
                  <Select
                    value={selectedCompanyPost}
                    onChange={(e) => setSelectedCompanyPost(e.target.value)}
                    placeholder="Comapny"
                    variant="outlined"
                    fullWidth
                    size="small"
                  >
                    {carArray.map((val) => (
                      <MenuItem value={val.name}>{val.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  Vehicle Name
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={vehicleDetailsEnglish.vehicleName}
                  onChange={(e) =>
                    setVehicleDetailsEnglish({
                      ...vehicleDetailsEnglish,
                      vehicleName: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  Registration Number
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={vehicleDetailsEnglish.registrationNumber}
                  onChange={(e) =>
                    setVehicleDetailsEnglish({
                      ...vehicleDetailsEnglish,
                      registrationNumber: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  Chasis Number
                </InputLabel>
                <TextField
                  variant="outlined"
                  placeholder="Chasis No "
                  size="small"
                  fullWidth
                  value={vehicleDetailsEnglish.chasisNo}
                  onChange={(e) =>
                    setVehicleDetailsEnglish({
                      ...vehicleDetailsEnglish,
                      chasisNo: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid style={{ marginTop: "5px" }} item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                    Registration Date/Year
                  </InputLabel>
                  <DatePicker
                    openTo="year"
                    views={["year", "month", "date"]}
                    // label="Registration Date/Year"
                    // helperText="Start from year selection"
                    value={selectedDatePost}
                    onChange={setSelectedDatePost}
                    variant="inline"
                    format="dd/MM/yyyy"
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  Vehicle Color
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={vehicleDetailsEnglish.color}
                  onChange={(e) =>
                    setVehicleDetailsEnglish({
                      ...vehicleDetailsEnglish,
                      color: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  Milage in km
                </InputLabel>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={vehicleDetailsEnglish.milage}
                  onChange={(e) =>
                    setVehicleDetailsEnglish({
                      ...vehicleDetailsEnglish,
                      milage: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  Price
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={vehicleDetailsEnglish.price}
                  onChange={(e) =>
                    setVehicleDetailsEnglish({
                      ...vehicleDetailsEnglish,
                      price: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <InputLabel style={{ margin: "5px", fontSize: "13px" }}>
                  Description
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline={true}
                  minRows={10}
                  value={vehicleDetailsEnglish.description}
                  onChange={(e) =>
                    setVehicleDetailsEnglish({
                      ...vehicleDetailsEnglish,
                      description: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <CustomizedSnackbars
        open={isError}
        message={"Please fill complete information"}
        closeSnack={() => setIsError(false)}
      />
      <SuccesSnack
        open={isSuccess}
        message={"Prize Created Succesfully"}
        closeSnack={() => setIsSuccess(false)}
      />
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{modalTitle}</DialogTitle>
        <DialogContent style={{ minWidth: "600px", minHeight: "150px" }}>
          {renderEnglish()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
