import React, { useState, useEffect } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
} from "@material-ui/core";
import { useStyles } from "../styles";
import { Loader } from "../../../components";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../react_dates_overrides.css";
import axios from "axios";
import { get_all_support } from "./../../../api/index";

const Support = () => {
  const classes = useStyles();
  const [support, setSupport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const tableHeading = ["ID", "Question", "Username","Purcahser Email"];

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(get_all_support)
      .then((res) => {
        console.log(res.data, "asdasdsadsasad");
        setSupport(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err, "asdasdad");
        setIsLoading(false);
      });
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <NavBar>
      <Loader isloading={isLoading} />
      <Container maxWidth="xl">
        <Paper>
          <TableContainer component={Paper} style={{ padding: "20px" }}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {tableHeading.map((heading, index) => {
                    return (
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          padding: "6px",
                        }}
                      >
                        {heading}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {support?.length > 0 &&
                  support
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, ind) => (
                      <TableRow key={ind} className={classes.tableRow}>
                        <TableCell align="center" className={classes.rowName}>
                          {ind + 1}
                        </TableCell>
                        <TableCell align="center" className={classes.rowName}>
                          {row.message}
                        </TableCell>
                        <TableCell align="center" className={classes.rowName}>
                          {row.userDetails?.name}
                        </TableCell>
                        <TableCell align="center" className={classes.rowName}>
                          {row.userDetails?.email}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={support.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </Container>
    </NavBar>
  );
};

export default Support;
