import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Paper,
  Icon,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  activeMainContainer: {
    border: "1px solid #f6f7fb",
    padding: "6px 0px 6px 10px",
    cursor: "pointer",
    "&.MuiListItem-root .MuiListItemText-root .MuiListItemText-primary": {
      fontSize: "13px",
      color: "#008ECE",
    },
    "&.MuiListItem-root .MuiListItemAvatar-root .MuiAvatar-root": {
      color: "#008ECE",
      background: "none",
    },
  },
  inActiveMainContainer: {
    border: "1px solid #f6f7fb",
    padding: "6px 0px 6px 10px",
    cursor: "pointer",
    "&.MuiListItem-root .MuiListItemText-root .MuiListItemText-primary": {
      fontSize: "13px",
      // color: "#008ECE",
    },
    "&.MuiListItem-root .MuiListItemAvatar-root .MuiAvatar-root": {
      color: "#333333",
      background: "none",
    },
  },
}));

const InnerSideBarOptions = ({ options, onPress }) => {
  const classes = useStyles();
  const [activeColor, setActiveColor] = useState(0);
  return (
    <>
      {options.map((option, index) => {
        return (
          <Paper>
            <ListItem
              onClick={() => {
                onPress(option);
                setActiveColor(index);
              }}
              className={
                activeColor === index
                  ? classes.activeMainContainer
                  : classes.inActiveMainContainer
              }
            >
              <ListItemAvatar style={{ minWidth: "35px" }}>
                {/* <Avatar
                  style={{ width: "20px", height: "20px", borderRadius: 10 }}
                >
                  <Icon>group</Icon>
                </Avatar> */}
              </ListItemAvatar>
              <ListItemText primary={option} />
            </ListItem>
          </Paper>
        );
      })}
    </>
  );
};

export default InnerSideBarOptions;
