import React, { useState, useEffect } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  TextField,
  Typography,
  Switch,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../styles";
import firebase from "firebase";
import { Loader, successHelper } from "../../../components";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../react_dates_overrides.css";

const Payments = () => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    status: true,
  });
  const [allUsers, setAllUsers] = useState([]);
  const [allPayments, setAllPayments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState("All");
  const [dateRange, setdateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const { startDate, endDate } = dateRange;
  const [focus, setFocus] = useState(null);

  const tableHeading = ["ID", "Date", "Amount", "Payment Method", "User Name"];

  useEffect(() => {
    setIsLoading(true);
    firebase
      .database()
      .ref("users")
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let userData = Object.values(data);
        setAllUsers(userData);
        setIsLoading(false);
      });

    firebase
      .database()
      .ref("payments")
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let paymentsData = Object.values(data);
        setAllPayments(paymentsData);
        setFilteredData(paymentsData);

        setIsLoading(false);
      });
  }, []);
  const paymentsTable = () => {
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleChangeSwitch = (key) => {
      // firebase.database().ref(`controllers/${key}`).update({
      //   ...allControllers[key],
      //   status: !allControllers[key].status
      // }).then(res => {
      //   alert('Updated Succesfully')
      // }).catch(err => {
      // })
    };

    // for firebaseIntegration
    // let users = Object.keys(allUsers)
    let users = [
      {
        date: "12/12/2021",
        amount: "45$",
        paymentType: "Stripe",
        user: "Test Event",
      },
      {
        date: "10/12/2021",
        amount: "850$",
        paymentType: "Stripe",
        user: "Test 2",
      },
    ];
    return (
      <Paper>
        <TableContainer component={Paper} style={{ padding: "20px" }}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tableHeading.map((heading, index) => {
                  return (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        padding: "6px",
                      }}
                    >
                      {heading}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 &&
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, ind) => (
                    <TableRow key={ind} className={classes.tableRow}>
                      <TableCell align="center" className={classes.rowName}>
                        {ind + 1}
                      </TableCell>
                      <TableCell align="center" className={classes.rowName}>
                        {row?.createdDate}
                      </TableCell>
                      <TableCell align="center" className={classes.rowName}>
                        {row?.amount}
                      </TableCell>
                      <TableCell align="center" className={classes.rowName}>
                        {row?.paymentMethod}
                      </TableCell>
                      <TableCell align="center" className={classes.rowName}>
                        {row?.user?.name}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    );
  };

  const handleOnDateChange = (startDate, endDate) => {
    let start = startDate.startDate;
    let end = startDate.endDate;
    setdateRange({
      startDate: start,
      endDate: end,
    });
  };
  const handleSeach = () => {
    // let filtered = []
    // setIsLoading(true)
    // events.map(val => {
    //   let date = new Date(val.eventDate)
    //   let diff = moment(`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`).isBetween(dateRange.startDate, dateRange.endDate)
    //   if (diff) {
    //     filtered.push(val)
    //   }
    // })
    // setTimeout(() => {
    //   setIsLoading(false)
    // }, 2000);
  };
  const handleSelectedUser = (e) => {
    setSelectedUser(e.target.value);
    if (e.target.value == "All") {
      setFilteredData(allPayments);
    } else {
      let selectedUserData = [];
      allPayments.map((val) => {
        if (val.user?.name == e.target.value) {
          selectedUserData.push(val);
        }
      });
      setFilteredData(selectedUserData);
    }
  };

  return (
    <NavBar>
      <Container maxWidth="xl">
        <Paper className={classes.paperRoot}>
          <Grid container spacing={2} alignItems="flex-end">
            {/* <Grid item xs={12} sm={8} md={4}>
      
              <DateRangePicker
                isOutsideRange={() => false}
                startDatePlaceholderText="Start Date"
                startDate={startDate}
                onDatesChange={handleOnDateChange}
                endDatePlaceholderText="End Date"
                endDate={endDate}
                numberOfMonths={2}
                displayFormat="MMM D Y"
                showClearDates={true}
                focusedInput={focus}
                onFocusChange={(focus) => setFocus(focus)}
                startDateId="startDateMookh"
                endDateId="endDateMookh"
                minimumNights={0}
                small

              />
            </Grid> */}
            <Grid item xs={12} sm={4} md={3}>
              <FormControl
                style={{ width: "100%" }}
                variant="outlined"
                className={classes.userRoleStyle}
              >
                <Select
                  // labelId="Event"
                  id="Event"
                  value={selectedUser}
                  onChange={handleSelectedUser}
                  displayEmpty
                  // label="Search By User"
                  fullWidth
                >
                  <MenuItem value={`All`}>{`All`}</MenuItem>
                  {allUsers.length > 0 &&
                    allUsers.map((item) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              {/* <div>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.createBtn}
                  style={{ textTransform: "none", padding: '8px 40px', width: '25px' }}
                  onClick={handleSeach}
                >
                  Search
                </Button>
              </div> */}
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          spacing={2}
          style={{ marginTop: "5px", marginBottom: "3%" }}
        >
          <Loader isloading={isLoading} />
        </Grid>
        <div>{paymentsTable()}</div>
      </Container>
    </NavBar>
  );
};

export default Payments;
