import Swal from 'sweetalert2';

class DeleteHelper {
	handleDelete(doAlert, deleteSomehing, y) {
		if (doAlert) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#9f1701',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then(result => {
				if (result.isConfirmed) {
					deleteSomehing(y.order_id);
				}
			});
		}
	}
}

export default new DeleteHelper();
