import React, { useState, useEffect } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  TextField,
  Typography,
  Switch,
  InputLabel,
  FormControl,
  Select,
  MenuItem

} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../styles";
import firebase from "firebase"
import { Loader, successHelper ,SuccesSnack} from "../../../components";
import { DateRangePicker } from "react-dates";
import moment from 'moment'
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../react_dates_overrides.css";




const AboutUs = () => {
  const classes = useStyles();
  const [price, setPrice] = React.useState('')
  const [isOpenSnack, setIsOpenSnack] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)


  useEffect(() => {
    setIsLoading(true)
    firebase.database().ref("ticketPrice").on("value", snapshot => {
      let data = snapshot.val() ? snapshot.val() : {}
      if(data){
        setPrice(data.price)
      }

    })



  }, [])


 
  const handleSave = () => {
    firebase.database().ref("ticketPrice").set(
      { price }
    ).then(res => {
      setIsLoading(false)
      setIsOpenSnack(true)
     
    }).catch((err) => {
      setIsLoading(false)

      console.log(err, 'errrr')
    })
  

  }

  return (
    <NavBar>
      <Container maxWidth="xl">
      <SuccesSnack open={isOpenSnack} message={"Details Saved Succesfully"} closeSnack={setIsOpenSnack} />

          <Grid container spacing={2} alignItems="flex-end">
            
            <Grid item xs={12} sm={12} md={3}>

              <TextField
                variant="outlined"
                placeholder="Price"
                label="Price"
                size="small"
                fullWidth
                value={price}
                onChange={e => setPrice(e.target.value)}
              />
            
            </Grid>
           
     
          </Grid>
          <Grid container spacing={2} style={{marginTop:'5px'}}>

          <Grid item xs={12} sm={4} md={8}>
              {/* <div > */}
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.createBtn}
                  style={{ textTransform: "none", padding: '8px 40px', width: '36%' }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              {/* </div> */}
            </Grid>
            </Grid>
      </Container>
    </NavBar>
  );
};

export default AboutUs;
