import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TextField,
  Grid,
  Button,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "../styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";
import firebase from "firebase";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import AddIcon from "@material-ui/icons/Add";
import { Delete } from "@material-ui/icons";
import { Loader, successHelper } from "../../../components";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../react_dates_overrides.css";
import EventTabs from "./EventTabs";
import EventBookingTable from "./EventBookingTable";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { get_event_detail_by_id } from "../../../api";
import { useParams } from "react-router-dom";
import EventsChart from "./EventsChart";

const EventDetails = ({ history }) => {
  const { alllangData, fireBaseKey } = history.location.state;

  const classes = useStyles();
  const params = useParams();

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2021-08-18T21:11:54")
  );
  const [selectedDateEvent, setSelectedDateEvent] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const [lastRegistrationDate, setLastRegistrationDate] = React.useState(
    new Date("2021-08-18T21:11:54")
  );
  const [City, setCity] = React.useState([
    { name: "Karachi", id: 0 },
    { name: "Lahore", id: 1 },
    { name: "Islamabad", id: 2 },
  ]);
  const [selectedCity, setSelectedCity] = React.useState("");

  const [company, setCompany] = React.useState([
    { name: "Suzuki", id: 0 },
    { name: "Toyota", id: 1 },
    { name: "Honda", id: 2 },
  ]);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [vehicleDetails, setVehicleDetails] = useState({
    vehicleName: "",
    registrationNumber: "",
    color: "",
    milage: "",
    price: "",
    description: "",
  });
  const [eventDetail, setEventDetail] = useState({
    eventName: "",
    prizeName: "",
    prizeDescription: "",
    eventDesscription: "",
  });
  const [vehicleImage, setVehicleImages] = useState([]);
  const [buttonStyle, setButtonStyle] = useState({ display: "none" });
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [focus, setFocus] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [language, setLanuage] = useState("en");
  const [chartData, setChartData] = useState([]);
  const [languageData, setLanguageData] = useState(null);
  const [langugaeKey, setLangugaekey] = useState(null);
  const [data, setData] = useState([]);
  const [paidParticipants, setPaidParticipants] = useState([]);
  const [allTickets, setAllTickets] = useState([]);

  const [dateRange, setdateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChangeEvent = (date) => {
    setSelectedDateEvent(date);
  };
  const handleEndDate = (date) => {
    setLastRegistrationDate(date);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  let dateFormatter = (value) => {
    let selectedEventFormatted = new Date();
    let registrationDate = value.split("-");
    selectedEventFormatted.setDate(registrationDate[0]);
    selectedEventFormatted.setMonth(registrationDate[1] - 1);
    selectedEventFormatted.setFullYear(registrationDate[2]);
    return selectedEventFormatted;
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  const getEventDetails = () => {
    setIsLoading(true);
    axios
      .get(`${get_event_detail_by_id}/${params.id}`)
      .then((res) => {
        let result = res.data.data;
        setData(result);

        if (result?.tickets?.length > 0) {
          let tempArr = [];

          result.tickets.map((item) => {
            if (item.ticketStatus === "Paid") {
              tempArr.push(item);
            }
            setAllTickets(tempArr);
          });

          let ticketsSoldData = [];

          result.tickets.map((val, ind) => {
            if (val.ticketStatus === "Paid" && ticketsSoldData.length > 0) {
              for (let i = 0; i < ticketsSoldData.length; i++) {
                if (
                  ticketsSoldData[i].date ==
                  moment(val.date).format("DD-MMM-YYYY")
                ) {
                  ticketsSoldData[i].tickets = ticketsSoldData[i].tickets + 1;
                } else {
                  ticketsSoldData.push({
                    date: moment(val.date).format("DD-MMM-YYYY"),
                    tickets: 1,
                  });
                }
              }
            } else {
              ticketsSoldData.push({
                date: moment(val.date).format("DD-MMM-YYYY"),
                tickets: 1,
              });
            }
          });

          setChartData(ticketsSoldData);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err, "laksdjlsakjd");
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   if (data?.participant?.length > 0) {
  //     let tempArr = [];

  //     data.participant.map((item) => {
  //       if (item.isPaidPrice === "Paid") {
  //         tempArr.push(item);
  //       }
  //       setPaidParticipants(tempArr);
  //     });
  //   }
  // }, [data]);

  // useEffect(() => {
  //   console.log(data?.participant, "datttt");
  //   // creating data for chart
  //   let ticketsSoldData = [];
  //   if (data?.participant?.length > 0) {
  //     data.participant.map((val, ind) => {
  //       if (val.isPaidPrice === "PayLater") return;
  //       if (ticketsSoldData.length > 0) {
  //         for (let i = 0; i < ticketsSoldData.length; i++) {
  //           if (
  //             ticketsSoldData[i].date == moment(val.date).format("DD-MMM-YYYY")
  //           ) {
  //             ticketsSoldData[i].tickets = ticketsSoldData[i].tickets + 1;
  //           } else {
  //             ticketsSoldData.push({
  //               date: moment(val.date).format("DD-MMM-YYYY"),
  //               tickets: 1,
  //             });
  //           }
  //         }
  //       } else {
  //         ticketsSoldData.push({
  //           date: moment(val.date).format("DD-MMM-YYYY"),
  //           tickets: 1,
  //         });
  //       }
  //     });
  //   }
  //   setChartData(ticketsSoldData);
  // }, [data]);

  const renderImageCard = (value, index) => {
    let handleDelte = () => {
      let img = [...vehicleImage];
      img.splice(index, 1);
      setVehicleImages(img);
    };

    return (
      <Card className={classes.eventCardRoot}>
        <CardMedia className={classes.eventCard} image={value} />
        <div
          onClick={handleDelte}
          style={{
            cursor: "pointer",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            paddingTop: "3px",
            paddingTop: "3px",
            color: "#fff",
            backgroundColor: "#ec5a4f",
            fontSize: "16px",
          }}
        >
          Delete
          <Delete
            style={{ color: "#fff", fontSize: "18px", marginTop: "1px" }}
          />
        </div>
      </Card>
    );
  };

  return (
    <NavBar>
      <Container maxWidth="xl">
        <Loader isloading={isLoading} />
        <Paper
          elevation={2}
          style={{
            padding: "15px",
            borderRadius: "5px",
            marginTop: "2%",
            marginBottom: "2%",
          }}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Booking Details
          </Typography>

          {chartData.length > 0 && <EventsChart data={chartData} />}
        </Paper>
        <EventBookingTable
          eventTable={allTickets}
          // eventTable={data?.participant}
          eventDetails={data}
          fireBaseKey={fireBaseKey}
          alllangData={data}
          // getEventData={getEventData}
          params={params}
          getEventDetails={getEventDetails}
        />
      </Container>
    </NavBar>
  );
};

export default EventDetails;
