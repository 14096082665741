import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { InputLabel, TextField } from "@material-ui/core";
import firebase from "firebase";
import { useStyles } from "./../styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditDialog({
  openModal,
  setOpen,
  data,
  setSnackMessage,
  setIsOpenSnack,
}) {
  const classes = useStyles();
  const [username, setUsername] = useState(data.data.name);
  const [alternative, setAlternative] = useState(data.data.alternative);
  const [phone, setPhone] = useState(data.data.phone);
  const [email, setEmail] = useState(data.data.email);
  console.log(data.data, "dataaaaaaaa");

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    setUsername(data.data.name);
    setEmail(data.data.email);
    setPhone(data.data.phone);
    setAlternative(data.data.alternative);
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    firebase
      .database()
      .ref(`users/${data.key}`)
      .update({
        ...data.data,
        name: username,
        phone,
        alternative,
      })
      .then((res) => {
        setSnackMessage("User Updated Succesfully");
        setIsOpenSnack(true);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ classes: { root: classes.usersDialog } }}
      >
        <DialogTitle id="alert-dialog-slide-title">{"Edit User"}</DialogTitle>
        <DialogContent>
          <InputLabel className={classes.inputlabel}>Username</InputLabel>
          <TextField
            name="User Name"
            variant="outlined"
            size="small"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
          />
          <InputLabel className={classes.inputlabel}>Email</InputLabel>
          <TextField
            name="Email"
            variant="outlined"
            size="small"
            value={email}
            // onChange={e=>setUsername(e.target.value)}
            disabled={true}
            fullWidth
          />
          <InputLabel className={classes.inputlabel}>Contact</InputLabel>
          <TextField
            name="Contact"
            variant="outlined"
            size="small"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
          />
          <InputLabel className={classes.inputlabel}>
            Alternative Contact
          </InputLabel>
          <TextField
            name="Alternative"
            variant="outlined"
            size="small"
            // value={inputValue.roleName}
            // onChange={onHandleChange}
            value={alternative}
            onChange={(e) => setAlternative(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
