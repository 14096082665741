import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  IconButton,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  MoveToInbox as InboxIcon,
  Menu as MenuIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './navigationStyles';

export default function MiniDrawer(props) {
  let adminNav = [
    {
      name: 'Dashboard',
      link: 'dashboard',
      icon: 'dashboard',
    },
    // {
    //   name: "All Events",
    //   link: "Events",
    //   icon: "festival",
    // },
    // {
    //   name: "Post Event",
    //   link: "Post_Event",
    //   icon: "emoji_events",
    // },
    {
      name: 'Controllers',
      link: 'Create_Controller',
      icon: 'person',
    },
    {
      name: 'Users',
      link: 'Users',
      icon: 'group',
    },
    // {
    //   name: "Payments",
    //   link: "Payments",
    //   icon: "attach_money",
    // },
    // {
    //   name: "Manual Payments",
    //   link: "Mannual_Payments",
    //   icon: "person",
    // },
    // {
    //   name: "Ticket",
    //   link: "Ticket_Price",
    //   icon: "person",
    // },
    {
      name: "FAQ's",
      link: 'Faq',
      icon: 'quiz',
    },
    {
      name: 'Support',
      link: 'Support',
      icon: 'support_agent',
    },
    {
      name: 'Car Features',
      link: 'carFeatures',
      icon: 'directions_car',
    },
    {
      name: 'Post Car',
      link: 'AddPost',
      icon: 'post_add',
    },
    {
      name: 'Settings',
      link: 'Settings',
      icon: 'settings',
    },
    // {
    //   name: "Settings",
    //   link: "Settings",
    //   icon: "person",
    // },
  ];

  let controllerNav = [
    {
      name: 'Post Car',
      link: 'AddPost',
      icon: 'post_add',
    },
    // {
    //   name: "All Posts",
    //   link: "Events",
    //   icon: "festival",
    // },
    // {
    //   name: "Post Events",
    //   link: "Post_Event",
    //   icon: "emoji_events",
    // },
  ];

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [userType, setUserType] = useState({});
  const params = useParams();
  const [historyName, setHistoryName] = useState(
    history.location.pathname != '/'
      ? history.location.pathname.replace('/', '')
      : 'Home'
  );

  const [customNavigation, setCustomNavigation] = useState([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloaseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'));
    setUserType(user);
    if (user?.userType === 'admin') {
      setCustomNavigation(adminNav);
    } else {
      setCustomNavigation(user.permissions);
    }
  }, []);

  const navigateToPage = (name) => {
    if (name == 'Home') {
      history.push('/');
    } else {
      history.push(`/${name}`);
    }
    // setHistoryName(history.location.pathname != "" ? history.location.pathname : "hi");
  };

  const handlLogout = () => {
    localStorage.clear();
    history.push('/login');
  };
  let navName = historyName.split('_');
  console.log(navName, 'historyNamehistoryName');

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ paddingRight: 0 }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon style={{ color: '#5769f3' }} />
          </IconButton>

          <div className={classes.profileContainer}>
            <Typography variant='h5' noWrap className={classes.heading}>
              {historyName == 'dashboard'
                ? 'Home'
                : historyName == `Edit_Event/${params.id}`
                ? 'Edit Event'
                : historyName == `Event_Detail/${params.id}`
                ? 'Event Detail'
                : navName.length > 1
                ? `${navName[0]} ${navName[1]}`
                : historyName}
            </Typography>
            <div style={{ flex: 1 }}></div>

            <ListItem className={classes.profileItem}>
              <ListItemAvatar style={{ minWidth: '45px' }}>
                <Avatar style={{ width: '32px', height: '32px' }}></Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant='h1'
                      style={{
                        color: 'black',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      {userType?.user?.name}
                    </Typography>
                    <IconButton
                      aria-label='account of current user'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      onClick={handleMenu}
                      style={{ width: '10px', height: '10px' }}
                    >
                      <ExpandMoreIcon style={{ color: '#a2a1a6' }} />
                    </IconButton>
                  </div>
                }
                // secondary="Pakistan"
                className={classes.secondaryTypo}
              />
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openMenu}
                onClose={handleCloaseMenu}
              >
                <MenuItem onClick={handlLogout}>Logout</MenuItem>
              </Menu>
            </ListItem>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton
            onClick={handleDrawerClose}
            style={{
              color: '#5769f3',
              borderRadius: '50%',
              background: '#fff',
              padding: '5px',
            }}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <List>
          {customNavigation.map((text, index) => {
            return (
              <ListItem
                onClick={() => {
                  navigateToPage(text.link);
                }}
                style={{
                  background: historyName === text.link ? '#4b5dd9' : null,
                }}
                button
                key={text.name}
              >
                <ListItemIcon
                  style={{
                    minWidth: '35px',
                    justifyContent: !open ? 'center' : '',
                  }}
                >
                  <Icon
                    color='action'
                    style={{ color: '#fff', fontSize: '17px' }}
                  >
                    {text.icon}
                  </Icon>
                </ListItemIcon>
                {open ? (
                  <ListItemText
                    primary={text.name}
                    className={classes.textTypo}
                  />
                ) : null}
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
