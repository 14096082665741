import React, { useState, useEffect } from 'react';
import NavBar from '../../../navbar/AdminNavigation';
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Switch,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../styles';
import firebase from 'firebase';
import {
  Loader,
  successHelper,
  SuccesSnack,
  ErrorSnack,
} from '../../../components';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../react_dates_overrides.css';
import PostDialog from './PostDialog';
import Swal from 'sweetalert2';
import axios from 'axios';
import { payCommission } from '../../../api';
import { useHistory } from 'react-router-dom';
const PostDetails = (props) => {
  const classes = useStyles();
  const [allPosts, setAllPosts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const[isOpenSnack,setIsOpenSnack]=useState(false);
  const[isOpenSnackError,setIsOPenSnackError]=useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = useState('all');
  const [filterArr, setFilterArr] = useState([]);
  const userData = JSON.parse(localStorage.getItem('user'));
  let postDetails = props.location.state;
  const history=useHistory();
  const [paymentStatus,setPaymentStatus]=useState(postDetails?.post?.commissionPaid)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeFilter = (e) => {
    let valueee = e.target.value;

    setFilter(valueee);

    if (valueee == 'all') {
      setFilterArr(allPosts);
    } else if (valueee == 'approval') {
      let newArr = allPosts.filter((item) => {
        return !item.post.isApprove ? item : null;
      });
      setFilterArr(newArr);
    } else {
      let myListingArr = allPosts.filter((item) =>
        item?.post.userId == userData.userID ? item : null
      );
      setFilterArr(myListingArr);
    }
  };

  const handleApprovePayment=()=>{
    let payload = {
      commissionPaid: true,
    };
    setIsLoading(true)
    axios
    .put(`${payCommission}/${postDetails?.post?._id}`, payload).then(res=>{
      setIsLoading(false)
      if (res?.status==200) {
        setIsOpenSnack(true);
        history.push({
          pathname: '/AddPost',
        })
      }else{
        setIsOPenSnackError(true)
      }
    }).catch(error=>{
      setIsLoading(false)
      console.log(error,"error");
    })
  }

  console.log(postDetails?.post?.commissionPaid,"postDetails?.post?.comissionPaidpostDetails?.post?.comissionPaid");
  return (
    <NavBar>
      <Loader isloading={isLoading} />
      <Container maxWidth='xl'>
        <Typography
          style={{ fontSize: '25px', fontWeight: 700, marginBottom: '10px' }}
        >
          Images
        </Typography>
        <Grid container spacing={3} style={{justifyContent:"space-between"}}>
          {postDetails?.post.postImages.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={3}>
                <div style={{ border: '1px solid #0000002e', height: '220px' }}>
                  <img src={item} width='100%' height='100%' />
                </div>
              </Grid>
            );
          })}

          <Grid item xs={12} sm={6} md={3} style={{display:"flex"}}>
            {paymentStatus == false ?
            <>
          <Typography
          style={{ fontSize: '14px', fontWeight: 700,  }}
        >
          Approve Service Charges Payment
        </Typography>
          <Switch
            size='small'
            checked={paymentStatus}
          onClick={handleApprovePayment}
          />
          </>
          :
          <Typography
          style={{ fontSize: '14px', fontWeight: 700,color:"green"  }}
        >
           Service Charges Payment Approved
        </Typography>
            }
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              style={{ fontSize: '25px', fontWeight: 700, margin: '10px 0' }}
            >
              Car Details
            </Typography>

            <div
              style={{
                background: '#fff',
                borderRadius: '10px',
                marginTop: '10px',
                border: '1px solid #0000002e',
                padding: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Vehicle Name:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.vehicleName}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Vehicle Color:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.color}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Registered City:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.registeredCity}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  posted Date:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.postCreatedDate}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>Price: </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.price}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Registration Number:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.registrationNumber}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Chasis Number:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.chasisNo}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Posted By:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.postedBy}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>Milage: </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.milage}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Description:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.description}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Comission:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.post?.comission}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Typography style={{ fontWeight: '700' }}>
                  Features:{' '}
                </Typography>
                {postDetails?.post?.options.map((item) => {
                  return (
                    <Typography style={{ marginLeft: '10px' }}>
                      {item}{postDetails?.post?.options.length > 1 ? ',' : ''}
                    </Typography>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              style={{ fontSize: '25px', fontWeight: 700, margin: '10px 0' }}
            >
              User Details
            </Typography>
            <div
              style={{
                background: '#fff',
                borderRadius: '10px',
                marginTop: '10px',
                border: '1px solid #0000002e',
                padding: '10px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>Name: </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.userDetails?.name}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>Gender: </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.userDetails?.gender == ''
                    ? 'Not specified'
                    : postDetails?.userDetails?.gender}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>DOB: </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.userDetails?.dateOfBirth
                    ? postDetails?.userDetails?.dateOfBirth
                    : 'Not specified'}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Phone Number:{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.userDetails?.phoneNumber}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>
                  Alternative Phone :{' '}
                </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.userDetails?.alternativePhone}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>Email: </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.userDetails?.email}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '700' }}>Type: </Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  {postDetails?.userDetails?.type}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography
              style={{ fontSize: '18px', fontWeight: 700, marginTop: "10px" }}
            >
              Service Charges Payment Proof
            </Typography>
            <div style={{ border: '1px solid #0000002e', height: '300px', marginTop: "10px" }}>
              <img src={postDetails?.post?.paymentProof} width='100%' height='100%' />
            </div>
          </Grid>
        </Grid>
        <ErrorSnack
          open={isOpenSnackError}
          message={"Something went wrong"}
          closeSnack={setIsOPenSnackError}
        />
        <SuccesSnack
          open={isOpenSnack}
          message={'Payment approved successfully'}
          closeSnack={setIsOpenSnack}
        />
      </Container>
      
    </NavBar>
  );
};

export default PostDetails;
