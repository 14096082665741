import React, { useState, useEffect } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../styles";
import firebase from "firebase";
import {
  Loader,
  successHelper,
  SuccesSnack,
  ErrorSnack,
} from "../../../components";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../react_dates_overrides.css";
import FaqDialog from "./FaqDialog";
import axios from "axios";
import { get_all_faq, delete_faq } from "../../../api";
import Swal from "sweetalert2";

const FAQ = () => {
  const classes = useStyles();
  const [faqs, setFaqs] = React.useState([{ question: "", answer: "" }]);
  const [isOpenSnack, setIsOpenSnack] = React.useState(false);
  const [isOpenSnackError, setIsOPenSnackError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const tableHeading = ["No", "Question", "Answer", "Action"];
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [isOpenModal, setIsOPenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const getAllFaqs = () => {
    setIsLoading(true);
    axios
      .get(get_all_faq)
      .then((res) => {
        console.log(res.data.data, "Asdsadsadsad");
        setIsLoading(false);
        setFaqs(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllFaqs();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSave = () => {
    let error = [];
    faqs.map((val) => {
      if (val.question == "" || val.answer == "") {
        error.push(true);
      }
    });
    if (error.includes(true)) {
      setIsOPenSnackError(true);
    } else {
      firebase
        .database()
        .ref("faqs")
        .set({ faqs: faqs })
        .then((res) => {
          setIsLoading(false);
          setIsOpenSnack(true);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log(err, "errrr");
        });
    }
  };
  const faqKeys = Object.keys(faqs);
  const handleChange = (e, ind) => {
    let tempAr = [...faqs];
    tempAr[ind][e.target.name] = e.target.value;
    setFaqs(tempAr);
  };
  const handleAdd = (e, ind) => {
    setIsOPenModal(true);
    setModalType("Create FAQ");
    // let tempAr = [...faqs]
    // tempAr.push({ question: '', answer: '' })
    // setFaqs(tempAr)
  };
  const handleRowClick = (row, key, ind) => {
    setIsOPenModal(true);
    setRowData(row);
    setSelectedRowIndex(key);
    setModalType("Update FAQ");
  };

  const handleDelete = (e, row) => {
    e.stopPropagation();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${delete_faq}/${row._id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Faq deleted successfully.", "success");
            getAllFaqs();
          })
          .catch((err) => {
            console.log(err, "faqqqaaaa");
          });
      }
    });
  };

  return (
    <NavBar>
      <Loader isloading={isLoading} />
      <Container maxWidth="xl">
        <FaqDialog
          openModal={isOpenModal}
          setOpen={setIsOPenModal}
          setModalTitle={setModalType}
          data={faqs}
          setIsOpenSnack={setIsOpenSnack}
          modalTitle={modalType}
          rowData={rowData}
          selectedRowIndex={selectedRowIndex}
          setIsOPenSnackError={setIsOPenSnackError}
          getAllFaqs={getAllFaqs}
        />
        <ErrorSnack
          open={isOpenSnackError}
          message={"Please Fill Complete Information In All Languages"}
          closeSnack={setIsOPenSnackError}
        />
        <SuccesSnack
          open={isOpenSnack}
          message={"Faqs Saved Succesfully"}
          closeSnack={setIsOpenSnack}
        />
        <Button
          variant="contained"
          size="small"
          color="primary"
          // className={classes.createBtn}
          style={{
            textTransform: "none",
            padding: "8px 40px",
            // width: "10%",
            marginBottom: "10px",
            float: "right",
            marginTop: "1%",
            marginRight: "1%",
          }}
          onClick={handleAdd}
        >
          Add Question
        </Button>

        {/* {
          faqs.map((val,ind) => {
            return (
              <>
                <Grid container spacing={2} alignItems="flex-end" style={{marginTop:'1%'}}>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      placeholder="Question"
                      label="Question"
                      size="small"
                      fullWidth
                      name='question'
                      value={val.question}
                      onChange={e => handleChange(e,ind)}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      placeholder="Answer"
                      label="Answer"
                      size="small"
                      fullWidth
                      name='answer'
                      value={val.answer}
                      onChange={e => handleChange(e,ind)}
                      multiline={true}
                      minRows={3}
                      required
                      
                    />

                  </Grid>


                </Grid>
              </>
            )
          })
        } */}
        <Paper>
          <TableContainer component={Paper} style={{ padding: "20px" }}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {tableHeading &&
                    tableHeading?.map((heading, index) => {
                      return (
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "13px",
                            padding: "6px",
                          }}
                        >
                          {heading}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {faqKeys &&
                  faqKeys
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, ind) => (
                      <TableRow
                        key={ind}
                        className={classes.tableRow}
                        style={{ cursor: "pointer" }}
                        onClick={(_) => handleRowClick(faqs[row], row, ind)}
                      >
                        <TableCell align="center" className={classes.rowName}>
                          {ind + 1}
                        </TableCell>
                        <TableCell align="center" className={classes.rowName}>
                          {faqs[row].question}
                        </TableCell>
                        <TableCell align="center" className={classes.rowName}>
                          {faqs[row].answer}
                        </TableCell>
                        <TableCell align="center" className={classes.rowName}>
                          <DeleteIcon
                            style={{ color: "red" }}
                            onClick={(e) => handleDelete(e, faqs[row])}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={faqKeys.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>

        <Grid container spacing={2}></Grid>
      </Container>
    </NavBar>
  );
};

export default FAQ;
