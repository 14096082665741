import React, { useState, useEffect } from 'react';
import NavBar from '../../../navbar/AdminNavigation';
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  TextField,
  Typography,
  Switch,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useStyles } from '../styles';
import firebase from 'firebase';
import { Loader, successHelper, SuccesSnack } from '../../../components';
import Swal from 'sweetalert2';
import UserEditDialog from './UserEditDialog';
import { get_all_users, update_user_status } from '../../../api';
import axios from 'axios';

const Users = () => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
    status: true,
  });
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpenSnack, setIsOpenSnack] = React.useState(false);
  const [openEdit, setIsOpenEdit] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState('');
  const [filter, setFilter] = useState('all');
  const [filterArr, setFilterArr] = useState([]);

  const [selectedUser, setSelectedUser] = useState({ key: '', data: '' });

  useEffect(() => {
    getAllUsers();
  }, []);
  console.log(allUsers, 'allUsersallUsersallUsers');

  const getAllUsers = () => {
    setIsLoading(true);

    axios
      .get(get_all_users)
      .then((res) => {
        console.log(res.data.data, 'sadsadsadasd');
        setIsLoading(false);
        setAllUsers(res.data.data);
        setFilterArr(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.data.data, 'errrr');
      });
  };

  const UsersTable = () => {
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleChangeSwitch = (key) => {
      let paylod = {
        userId: key._id,
        // status: true,
        status: !key.status,
      };

      axios
        .put(update_user_status, paylod)
        .then((res) => {
          console.log(res, 'sadsadsadasd');
          successHelper.handleSuccess('User Status Updated Succesfully', true);
          setIsLoading(false);
          getAllUsers();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err, 'errrr');
        });

      // firebase
      //   .database()
      //   .ref(`users/${key}`)
      //   .update({
      //     ...allUsers[key],
      //     isApproved: !allUsers[key].isApproved,
      //   })
      //   .then((res) => {
      //     setSnackMessage("Status Updated Succesfully");
      //     setIsOpenSnack(true);
      //   })
      //   .catch((err) => {});
    };

    const handleSearch = (e) => {
      // let filtered = 	eventTable.filter(item => {
      //     return item?.name.toLowerCase().includes(e.target.value.toLowerCase());
      // });
      // setUsers(filtered)
    };

    // for firebaseIntegration
    // let users = Object.keys(allUsers)
    const tableHeading = [
      'ID',
      'Name',
      'Email',
      'Contact',
      'Alternate number',
      'Id Card',
      // "Lisence Image",
      "Type",
      'Approved',
      // 'Assign Ticket'
    ];

    const handleOpen = (key, data) => {
      setIsOpenEdit(true);
      setSelectedUser({ key, data });
    };

    const handleChangeFilter = (e) => {
      let valueee = e.target.value;

      setFilter(valueee);

      if (valueee == 'all') {
        setFilterArr(allUsers);
      } else if (valueee == 'buyer') {
        let newArr = allUsers.filter((item) => {
          return item.type == 'Buyer' ? item : null;
        });
        setFilterArr(newArr);
      } else {
        let myListingArr = allUsers.filter((item) =>
          item.type == 'Seller' ? item : null
        );
        setFilterArr(myListingArr);
      }
    };

    return (
      <>
        <Grid
          container
          alignItems='flex-end'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: '15px',
          }}
        >
          <FormControl size='small' style={{ width: '20%' }}>
            <Select
              value={filter}
              onChange={handleChangeFilter}
              placeholder='Comapny'
              displayEmpty
              variant='outlined'
              fullWidth
              size='small'
            >
              <MenuItem value={'all'}>All</MenuItem>
              <MenuItem value={'buyer'}>Buyers</MenuItem>
              <MenuItem value={'seller'}>Sellers</MenuItem>
            </Select>
          </FormControl>

          {/* <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              placeholder="Search"
              label="Search"
              size="small"
              fullWidth
            // value={selectedCity}
            onChange={e => handleSearch(e)}
            />
          </Grid> */}
        </Grid>
        <Paper>
          <TableContainer style={{ padding: '20px' }}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  {tableHeading.map((heading, index) => {
                    return (
                      <TableCell
                        align='center'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '13px',
                          padding: '6px',
                        }}
                      >
                        {heading}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterArr.map((row, ind) => (
                  <TableRow
                    key={ind}
                    style={{ cursor: 'pointer' }}
                    className={classes.tableRow}
                    onClick={() => handleOpen(row)}
                  >
                    <TableCell align='center' className={classes.rowName}>
                      {ind + 1}
                    </TableCell>
                    <TableCell align='center' className={classes.rowName}>
                      {row?.name}
                    </TableCell>
                    <TableCell align='center' className={classes.rowName}>
                      {row?.email}
                    </TableCell>
                    <TableCell align='center' className={classes.rowName}>
                      {row?.phoneNumber}
                    </TableCell>
                    <TableCell align='center' className={classes.rowName}>
                      {row?.alternativePhone}
                    </TableCell>

                    <TableCell
                      onClick={(e) => e.stopPropagation()}
                      align='center'
                      className={classes.rowName}
                    >
                      {row?.image ? (
                        <a target='_blank' href={row?.image}>
                          View Image
                        </a>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell align='center' className={classes.rowName}>
                      {row?.type}
                    </TableCell>

                    {/* <TableCell
                    onClick={(e) => e.stopPropagation()}
                    align="center"
                    className={classes.rowName}
                  >
                    {allUsers[row]?.lisenceImage ? (
                      <a target="_blank" href={allUsers[row]?.lisenceImage}>
                        View Image
                      </a>
                    ) : (
                      "-"
                    )}
                  </TableCell> */}

                    {/* <TableCell align="center" className={classes.rowName}>
                    {allUsers[row]?.ticket}
                  </TableCell> */}
                    <TableCell
                      align='center'
                      className={classes.rowName}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Switch
                        checked={row.status}
                        onChange={() => handleChangeSwitch(row)}
                      />
                    </TableCell>
                    {/* <TableCell style={{ width: '20px' }} align="center" className={classes.rowName}>
                    <Button onClick={e => handleAssingTicket(e,row)} style={{ height: '33px', fontSize: '9px' }} color='primary' variant='contained'>Assign Ticket</Button>
                  </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={allUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </>
    );
  };

  return (
    <NavBar>
      <Container maxWidth='xl'>
        <UserEditDialog
          openModal={openEdit}
          setOpen={setIsOpenEdit}
          data={selectedUser}
          setSnackMessage={setSnackMessage}
          setIsOpenSnack={setIsOpenSnack}
        />
        <SuccesSnack
          open={isOpenSnack}
          message={snackMessage}
          closeSnack={setIsOpenSnack}
        />

        <Grid
          container
          spacing={2}
          style={{ marginTop: '5px', marginBottom: '3%' }}
        >
          <Loader isloading={isLoading} />
        </Grid>
        <div>{UsersTable()}</div>
      </Container>
    </NavBar>
  );
};

export default Users;
