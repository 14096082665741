import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Events from '../containers/adminDashboard/Events/AllEvents';
import PostEvent from '../containers/adminDashboard/Events/PostEvent';
import CreateController from './../containers/adminDashboard/createController/CreateController';
import Users from '../containers/adminDashboard/users/index.js';
import Payments from '../containers/adminDashboard/payments/index';
import MannualPayments from '../containers/adminDashboard/MannualPayments/index';
import Dashboard from '../containers/adminDashboard/Dasboard/index.js';
import EditEvent from '../containers/adminDashboard/Events/EditEvent';
import EventDetails from '../containers/adminDashboard/Events/EventDetails';
import Login from '../containers/Login';
import FAQ from '../containers/adminDashboard/FAQ';
import Support from '../containers/adminDashboard/Support';
import TIcketPrice from '../containers/adminDashboard/TIcketPrice';
import { useHistory } from 'react-router-dom';
import Settings from './../containers/adminDashboard/Settings/index';
import AddPost from '../containers/adminDashboard/AddPost/index';
import CarOptions from './../containers/adminDashboard/CarOptions/index';
import PostDetails from './../containers/adminDashboard/AddPost/PostDetails';
import ForgotPassword from '../containers/ForgotPassword';

const AdminRoutes = () => {
  let history = useHistory();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user?.userType == 'controller') {
      history.push(`/${user.permissions[0].link}`);
    } else if (user?.userType !== 'controller') {
      history.push('/dashboard');
    } else {
      history.push('/login');
    }
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => {
    let user = JSON.parse(localStorage.getItem('user'));
    let isUser = user?.userType;
    return (
      <Route
        {...rest}
        render={(props) =>
          isUser ? <Component {...props} /> : <Redirect to='/login' />
        }
      />
    );
  };
  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/forgotPassword' component={ForgotPassword} />
      <PrivateRoute component={Dashboard} path='/dashboard' exact />

      <PrivateRoute component={Events} path='/Events' exact />
      <PrivateRoute component={PostEvent} path='/Post_Event' exact />
      <PrivateRoute component={EditEvent} path='/Edit_Event/:id' exact />
      <PrivateRoute component={EventDetails} path='/Event_Detail/:id' exact />
      <PrivateRoute
        component={CreateController}
        path='/Create_Controller'
        exact
      />
      <PrivateRoute component={Users} path='/Users' exact />
      <PrivateRoute component={Payments} path='/Payments' exact />
      <PrivateRoute
        component={MannualPayments}
        path='/Mannual_Payments'
        exact
      />
      {/* <PrivateRoute component={About} path="/settings" exact /> */}
      <PrivateRoute component={FAQ} path='/Faq' exact />
      <PrivateRoute component={TIcketPrice} path='/Ticket_Price' exact />
      <PrivateRoute component={Support} path='/Support' exact />

      {/* New Routes */}
      <PrivateRoute component={Settings} path='/Settings' exact />
      <PrivateRoute component={CarOptions} path='/carFeatures' exact />
      <PrivateRoute component={AddPost} path='/AddPost' exact />
      <PrivateRoute component={PostDetails} path='/PostDetails' exact />
    </Switch>
  );
};

export default AdminRoutes;
