import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TextField, Grid } from "@material-ui/core";
import firebase from "firebase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { create_faq, update_faq } from "../../../api/index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditDialog({
  openModal,
  setOpen,
  data,
  setSnackMessage,
  setModalTitle,
  setIsOpenSnack,
  modalTitle,
  rowData,
  selectedRowIndex,
  setIsOPenSnackError,
  getAllFaqs,
}) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  let eventFirebaseKeyUSe = uuidv4();

  useEffect(() => {
    if (rowData && modalTitle !== "Create FAQ") {
      console.log(rowData, "rowData");
      setQuestion(rowData.question);
      setAnswer(rowData.answer);
    }
  }, [modalTitle, rowData]);

  console.log(rowData, "rowDatarowData");

  const handleClose = () => {
    setOpen(false);
    setModalTitle(null);
    setQuestion("");
    setAnswer("");
  };

  const handleSave = () => {
    if (question === "") setIsOPenSnackError(true);
    else if (answer === "") setIsOPenSnackError(true);
    else {
      if (modalTitle !== "Create FAQ") {
        let paylod = {
          question,
          answer,
        };

        axios
          .put(`${update_faq}/${rowData._id}`, paylod)
          .then((res) => {
            getAllFaqs();
            handleClose();
            setIsOpenSnack(true);
          })
          .catch((err) => {
            console.log(err, "errrr");
          });

        // firebase
        //   .database()
        //   .ref(`faqs/${selectedRowIndex}`)
        //   .update({
        //     question,
        //     answer,
        //   })
        //   .then((res) => {
        //     setIsOpenSnack(true);
        //     setOpen(false);
        //     handleClose();
        //   })
        //   .catch((err) => {
        //     console.log(err, "errrr");
        //   });
      } else {
        let paylod = {
          question,
          answer,
        };
        axios
          .post(create_faq, paylod)
          .then((res) => {
            console.log(res, "sadsadsadasd");
            handleClose();
            getAllFaqs();
            setIsOpenSnack(true);
            setOpen(false);
          })
          .catch((err) => {
            console.log(err, "errrr");
          });

        // firebase
        //   .database()
        //   .ref("faqs")
        //   .push({
        //     question,
        //     answer,
        //     language: "en",
        //     faqKey: eventFirebaseKeyUSe,
        //   })
        //   .then((res) => {
        //     setIsOpenSnack(true);
        //     setOpen(false);
        //     handleClose();
        //   })
        //   .catch((err) => {
        //     console.log(err, "errrr");
        //   });
      }
    }
  };

  const renderEnglish = () => {
    return (
      <>
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          style={{ marginTop: "1%" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              placeholder="Question"
              label="Question"
              size="small"
              fullWidth
              name="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              variant="outlined"
              placeholder="Answer"
              label="Answer"
              size="small"
              fullWidth
              name="answer"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              multiline={true}
              minRows={4}
              required
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{modalTitle}</DialogTitle>
        <DialogContent style={{ minWidth: "600px", minHeight: "150px" }}>
          {renderEnglish()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
