import React, { useState, useEffect } from 'react';
import NavBar from '../../../navbar/AdminNavigation';
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useStyles } from '../styles';
import firebase from 'firebase';
import {
  Loader,
  successHelper,
  SuccesSnack,
  ErrorSnack,
} from '../../../components';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../react_dates_overrides.css';
import FaqDialog from './CarFeatureDialog';
import axios from 'axios';
import { get_post_options, delete_post_options } from '../../../api';
import Swal from 'sweetalert2';

const CarOptions = () => {
  const classes = useStyles();
  const [carOptions, setCarOptions] = useState([]);
  const [isOpenSnack, setIsOpenSnack] = React.useState(false);
  const [isOpenSnackError, setIsOPenSnackError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const tableHeading = ['No', 'Name', 'Action'];
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [isOpenModal, setIsOPenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const getAllCarFeatures = () => {
    setIsLoading(true);
    axios
      .get(get_post_options)
      .then((res) => {
        console.log(res.data.data, 'Asdsadsadsad');
        setIsLoading(false);
        setCarOptions(res.data.data.reverse());
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllCarFeatures();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAdd = (e, ind) => {
    setIsOPenModal(true);
    setModalType('Create Car Feature');
    // let tempAr = [...faqs]
    // tempAr.push({ question: '', answer: '' })
    // setFaqs(tempAr)
  };
  const handleRowClick = (row, key, ind) => {
    setIsOPenModal(true);
    setRowData(row);
    setSelectedRowIndex(key);
    setModalType('Update Car Feature');
  };

  const handleDelete = (e, row) => {
    e.stopPropagation();

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${delete_post_options}/${row._id}`)
          .then((res) => {
            Swal.fire(
              'Deleted!',
              'Car feature deleted successfully.',
              'success'
            );
            getAllCarFeatures();
          })
          .catch((err) => {
            console.log(err, 'faqqqaaaa');
          });
      }
    });
  };

  return (
    <NavBar>
      <Loader isloading={isLoading} />
      <Container maxWidth='xl'>
        <FaqDialog
          openModal={isOpenModal}
          setOpen={setIsOPenModal}
          setModalTitle={setModalType}
          data={carOptions}
          setIsOpenSnack={setIsOpenSnack}
          modalTitle={modalType}
          rowData={rowData}
          setRowData={setRowData}
          selectedRowIndex={selectedRowIndex}
          setIsOPenSnackError={setIsOPenSnackError}
          getAllCarFeatures={getAllCarFeatures}
        />
        <ErrorSnack
          open={isOpenSnackError}
          message={'Feature name cannot be empty'}
          closeSnack={setIsOPenSnackError}
        />
        <SuccesSnack
          open={isOpenSnack}
          message={'Car Feature Saved Succesfully'}
          closeSnack={setIsOpenSnack}
        />
        <Button
          variant='contained'
          size='small'
          color='primary'
          // className={classes.createBtn}
          style={{
            textTransform: 'none',
            padding: '8px 40px',
            // width: "10%",
            marginBottom: '10px',
            float: 'right',
            marginTop: '1%',
            marginRight: '1%',
          }}
          onClick={handleAdd}
        >
          Add Car Feature
        </Button>

        <Paper>
          <TableContainer component={Paper} style={{ padding: '20px' }}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  {tableHeading &&
                    tableHeading?.map((heading, index) => {
                      return (
                        <TableCell
                          align='center'
                          style={{
                            fontWeight: 'bold',
                            fontSize: '13px',
                            padding: '6px',
                          }}
                        >
                          {heading}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              {console.log(carOptions, 'carOptionscarOptions')}
              <TableBody>
                {carOptions &&
                  carOptions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, ind) => (
                      <TableRow
                        key={ind}
                        className={classes.tableRow}
                        style={{ cursor: 'pointer' }}
                        onClick={(_) => handleRowClick(row, ind)}
                      >
                        <TableCell align='center' className={classes.rowName}>
                          {ind + 1}
                        </TableCell>
                        <TableCell align='center' className={classes.rowName}>
                          {row?.name}
                        </TableCell>
                        <TableCell align='center' className={classes.rowName}>
                          <DeleteIcon
                            style={{ color: 'red' }}
                            onClick={(e) => handleDelete(e, row)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={carOptions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>

        <Grid container spacing={2}></Grid>
      </Container>
    </NavBar>
  );
};

export default CarOptions;
