import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TextField, Grid, Typography } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';
import Dropzone from 'react-dropzone';

import Select from 'react-select';
import axios from 'axios';
import { create_add_post, update_add_post } from '../../../api/index';
import { Close } from '@material-ui/icons';
import { Loader } from '../../../components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function PostDialog({
  openModal,
  setOpen,
  data,
  setSnackMessage,
  setModalTitle,
  setIsOpenSnack,
  modalTitle,
  rowData,
  selectedRowIndex,
  setIsOPenSnackError,
  getAllAddPosts,
  carFeatures,
  selectedFeatures,
  setSelectedFeatures,
  inputValue,
  setInputValue,
  postImages,
  setPostImages,
}) {
  const [imageLoading, setImageLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (rowData && modalTitle !== 'Create Post') {
      setInputValue({
        registeredCity: rowData?.post?.registeredCity,
        vehicleName: rowData?.post?.vehicleName,
        registrationNumber: rowData?.post?.registrationNumber,
        color: rowData?.post?.color,
        milage: rowData?.post?.milage,
        price: rowData?.post?.price,
        description: rowData?.post?.description,
        chasisNo: rowData?.post?.chasisNo,
      });
      setPostImages(rowData?.post?.postImages);
      let tempArr = [];
      rowData?.post?.options.map((val) => {
        tempArr.push({
          label: val,
          value: val,
        });
      });
      setSelectedFeatures(tempArr);
    }
  }, [modalTitle, rowData]);

  console.log(rowData?.post, 'rowDatarowData');

  const handleChangeInput = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setInputValue({
      registeredCity: '',
      vehicleName: '',
      registrationNumber: '',
      color: '',
      milage: '',
      price: '',
      description: '',
      chasisNo: '',
    });
    setSelectedFeatures([]);
    setPostImages([]);
    setModalTitle(null);
    setOpen(false);
  };

  const handleSave = () => {
    if (
      inputValue.registeredCity === '' ||
      inputValue.vehicleName == '' ||
      inputValue.registrationNumber == '' ||
      inputValue.color == '' ||
      inputValue.milage == '' ||
      inputValue.price == '' ||
      inputValue.chasisNo == ''
    ) {
      setIsOPenSnackError(true);
      setSnackMessage('All fields required');
    } else if (selectedFeatures.length == 0) {
      setIsOPenSnackError(true);
      setSnackMessage('Please select Features');
    } else if (postImages.length == 0) {
      setIsOPenSnackError(true);
      setSnackMessage('Atleast one image for post is required');
    } else {
      let newArr = [];
      selectedFeatures.map((val) => {
        newArr.push(val.label);
      });
      if (modalTitle !== 'Create Post') {
        let paylod = {
          registeredCity: inputValue.registeredCity,
          vehicleName: inputValue.vehicleName,
          registrationNumber: inputValue.registrationNumber,
          color: inputValue.color,
          milage: JSON.parse(inputValue.milage),
          price: JSON.parse(inputValue.price),
          postImages: postImages,
          description: inputValue.description,
          chasisNo: JSON.parse(inputValue.chasisNo),
          options: newArr,
          userDetails: userData,
        };
        axios
          .put(`${update_add_post}/${rowData?.post._id}`, paylod)
          .then((res) => {
            getAllAddPosts();
            handleClose();
            setIsOpenSnack(true);
          })
          .catch((err) => {
            console.log(err, 'errrr');
          });
      } else {
        let payload = {
          registeredCity: inputValue.registeredCity,
          vehicleName: inputValue.vehicleName,
          registrationNumber: inputValue.registrationNumber,
          color: inputValue.color,
          milage: JSON.parse(inputValue.milage),
          price: JSON.parse(inputValue.price),
          postImages: postImages,
          description: inputValue.description,
          chasisNo: parseInt(inputValue.chasisNo),
          userId: userData.userID,
          options: newArr,
          userDetails: userData,
          postedBy: 'Admin',
          isApprove: true,
        };
        axios
          .post(create_add_post, payload)
          .then((res) => {
            console.log(res, 'sadsadsadasd');
            handleClose();
            getAllAddPosts();
            setIsOpenSnack(true);
            setOpen(false);
          })
          .catch((err) => {
            console.log(err, 'errrr');
          });
      }
    }
  };

  const handleAddImages = async (event) => {
    if (event) {
      setImageLoading(true);
      const form = new FormData();
      form.append('image', event);
      // form.append("upload_preset", "awfemeq5");
      try {
        let res = await axios.post('https://amberstore.pk/upload.php', form);
        if (res) {
          setImageLoading(false);
          setPostImages([...postImages, res.data.url]);
        }
      } catch (error) {
        console.log(error, 'ERRRRR=>>>>>');
        setImageLoading(false);
      }
    }
  };

  const handleChangeOptions = (option) => {
    setSelectedFeatures(option);
  };

  const handleRemoveImage = (ind) => {
    let tempArr = [...postImages];
    tempArr.splice(ind, 1);
    setPostImages(tempArr);
  };

  console.log(selectedFeatures, 'aslkdajsldjsalkd');

  return (
    <div>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <Loader isloading={imageLoading} />
        <DialogTitle id='alert-dialog-slide-title'>{modalTitle}</DialogTitle>
        <DialogContent style={{ minWidth: '600px', minHeight: '150px' }}>
          <Grid
            container
            spacing={2}
            alignItems='flex-end'
            style={{ marginTop: '1%' }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                placeholder='Registered City'
                label='Registered City'
                size='small'
                fullWidth
                name='registeredCity'
                value={inputValue.registeredCity}
                onChange={handleChangeInput}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                placeholder='Vehicle Name'
                label='Vehicle Name'
                size='small'
                fullWidth
                name='vehicleName'
                value={inputValue.vehicleName}
                onChange={handleChangeInput}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                placeholder='Registration Number'
                label='Registration Number'
                size='small'
                fullWidth
                name='registrationNumber'
                value={inputValue.registrationNumber}
                onChange={handleChangeInput}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                placeholder='Color'
                label='Color'
                size='small'
                fullWidth
                name='color'
                value={inputValue.color}
                onChange={handleChangeInput}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                placeholder='Milage'
                label='Milage'
                size='small'
                fullWidth
                type='number'
                name='milage'
                value={inputValue.milage}
                onChange={handleChangeInput}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                placeholder='Price'
                label='Price'
                size='small'
                fullWidth
                type='number'
                name='price'
                value={inputValue.price}
                onChange={handleChangeInput}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                placeholder='Description'
                label='Description'
                size='small'
                fullWidth
                multiline
                minRows={4}
                maxRows={8}
                name='description'
                value={inputValue.description}
                onChange={handleChangeInput}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                placeholder='Chasis Number'
                label='Chasis Number'
                size='small'
                fullWidth
                type='number'
                name='chasisNo'
                value={inputValue.chasisNo}
                onChange={handleChangeInput}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                options={carFeatures}
                isMulti
                placeholder='Select Features'
                onChange={handleChangeOptions}
                value={selectedFeatures}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropzone
                onDrop={(acceptedFiles) => handleAddImages(acceptedFiles[0])}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    style={{
                      height: '150px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      border: '1px dashed red',
                      cursor: 'pointer',
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <PhotoIcon />
                    <Typography variant='body2'>
                      Drop your image here, or <br />
                      select{' '}
                      <span style={{ color: '#3a92ce' }}>click to browse</span>
                    </Typography>
                  </div>
                )}
              </Dropzone>

              <Grid container spacing={2} style={{ marginTop: '5px' }}>
                {postImages.length > 0 &&
                  postImages.map((item, ind) => {
                    return (
                      <Grid item xs={12} sm={6} md={4}>
                        <div style={{ position: 'relative' }}>
                          <img
                            src={item}
                            width='100%'
                            height='150px'
                            alt='avatar'
                            style={{ border: '1px dashed black' }}
                          />
                          <Close
                            style={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              padding: '2px',
                              background: '#fff',
                              borderRadius: '50%',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleRemoveImage(ind)}
                          />
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' variant='contained'>
            Cancel
          </Button>
          <Button onClick={handleSave} color='primary' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
