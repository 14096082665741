import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  paperRoot: {
    padding: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  zone: {
    marginBottom: 0,
    fontWeight: 700,
    color: "#1186fa",
    marginRight: 10,
  },
  inputStyle: {
    "&.MuiFormControl-root .MuiInputBase-root": {
      background: "#f6f7fb",
      borderRadius: "12px",
    },
    "&.MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#eaeef4",
      },
  },
  textttt: {
    "&.MuiListItemText-root .MuiListItemText-primary": {
      fontWeight: 900,
      fontSize: "22px",
    },
  },
  percentTypo: {
    color: "#1286f9",
    fontWeight: 600,
    fontSize: "12px",
  },
  monthTypo: {
    color: "#b8b9c1",
    fontWeight: 600,
    fontSize: "12px",
  },
  listItemContainer: {
    "&.MuiListItemAvatar-root .MuiAvatar-root": {
      background: "#e6f2fe",
      borderRadius: "20%",
      width: "45px",
      height: "50px",
    },
    "&.MuiListItemAvatar-root .MuiAvatar-root .MuiTypography-root": {
      color: "#0a83f9",
      fontSize: "16px",
      fontWeight: 700,
    },
  },
  activeBtnContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 10px 10px",
    },
  },
  activeBtnContainer2: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  dateBtnsActive: {
    width: "80px",
    textTransform: "none",
    color: "#0a81f9",
    fontWeight: 700,
    background: "#fff",
    borderRadius: "5px",
  },
  dateBtnsInActive: {
    width: "80px",
    textTransform: "none",
    color: "#b1b3b8",
    fontWeight: 700,
    background: "none",
    borderRadius: "5px",
  },
  secondDateBtnsActive: {
    width: "80px",
    textTransform: "none",
    color: "#0a81f9",
    fontWeight: 700,
    background: "#f6f7fb",
    borderRadius: "5px",
  },
  targetHead: {
    "&.MuiListItemText-root .MuiTypography-root": {
      fontWeight: "bold",
      fontSize: "20px",
    },
  },
  reach: {
    fontSize: "25px",
    fontWeight: "bold",
    display: "flex",
  },
  cardContainer: {
    background: "#f6f7fb",
    marginRight: 10,
    // padding: "10px",
    paddingLeft: "50px",
  },
  organic: {
    "&.MuiListItemText-root .MuiTypography-root": {
      color: "#abadb8",
      fontSize: "12px",
    },
  },
  textPri: {
    "&.MuiListItemText-root .MuiListItemText-primary": {
      fontWeight: "bold",
      fontSize: "18px",
    },
  },
  reportBtn: {
    background: "#f1f4fd",
    padding: "5px 10px",
    textTransform: "none",
    color: "#3698fa",
    "&:hover": {
      background: "#f1f4fd",
    },
  },
  inputlabel: {
    margin: "8px 0 !important",
    fontSize: "13px !important",
  },
  userRoleStyle: {
    width: "100%",
    "&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root": {
      padding: "10px 12px",
    },
  },
  eventCardRoot: {
    // maxWidth: 345,
    position: "relative",
    marginTop: "10px",
  },
  eventCardRootVideo: {
    maxWidth: 540,
  },
  eventCard: {
    height: 180,
  },
  eventCardVdeo: {
    height: 250,
  },
  eventCardDate: {
    backgroundColor: "#5769f3",
    color: "#fff",
    width: "30%",
    borderRadius: "5px",
    marginTop: "8px",
    height: "30px",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  eventEditButton: {
    background: "#5769f3",
    color: "#fff",
    borderRadius: "5px",
    marginTop: "8px",
    height: "32px",
    textAlign: "center",
    "&:hover": {
      background: "#5769f3 !important",
    },
  },
  eventPostButton: {
    backgroundColor: "#5769f3",
    color: "#fff",
    borderRadius: "5px",
    marginTop: "8px",
    height: "32px",
    textAlign: "center",
    paddingBlock: "22px",
    fontWeight: "bold",
    "&:hover": {
      background: "#5769f3 !important",
    },
  },
  winnerButton: {
    backgroundColor: "#5769f3",
    color: "#fff !important",
    borderRadius: "5px",
    marginTop: "8px",
    height: "38px",
    textAlign: "center",
    "&:hover": {
      background: "#5769f3 !important",
    },
  },

  cardContainer: {
    background: "#f6f7fb",
    // marginRight: 10,
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    // paddingLeft: "50px",
  },
  paperRoot: {
    padding: "20px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  outerContainer: {
    background: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    border: "1px solid #e3e6f0",
    borderRadius: ".35rem",
    width: "30%",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "48%",
      padding: "0 10px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0 10px 10px",
    },
  },
  detailContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "40px 20px",
  },
  changePicture: {
    backgroundColor: "#5769f3",
    width: "40%",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "5px",
    height: "25px",
    textAlign: "center",
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    borderColor: "#5769f3",
  },

  usersDialog: {
    width: "500px",
  },
}));
