import React, { useState, useEffect } from 'react';
import NavBar from '../../../navbar/AdminNavigation';
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useStyles } from '../styles';
import firebase from 'firebase';
import {
  Loader,
  successHelper,
  SuccesSnack,
  ErrorSnack,
} from '../../../components';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { get_all_faq, delete_faq } from '../../../api';
import Swal from 'sweetalert2';
import {
  get_subscription,
  update_subscription,
  get_commission,
  update_commission,
  get_account_Details,
  create_account_Details,
  update_account_Details,
} from './../../../api/index';

const Settings = () => {
  const classes = useStyles();
  const [isOpenSnack, setIsOpenSnack] = React.useState(false);
  const [isOpenSnackError, setIsOPenSnackError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [subcriptionData, setSubscriptionData] = useState(null);
  const [subcriptionCharges, setSubscriptionCharges] = useState(0);
  const [snackMessage, setSnackMessage] = useState('');
  const [commissionData, setCommissionData] = useState(null);
  const [commissionPer, setCommissionPer] = useState('');
  const [accountTitle,setAccountTitle]=useState('');
  const [accountNo,setAccountNo]=useState('');
  const[accountData,setAccountData]=useState(null)
  const getSubsctiption = () => {
    setIsLoading(true);
    axios
      .get(get_subscription)
      .then((res) => {
        setIsLoading(false);
        setSubscriptionData(res.data.data);
        setSubscriptionCharges(res.data.data.charges);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getAccountDetails = () => {
    setIsLoading(true);
    axios
      .get(get_account_Details)
      .then((res) => {
        setIsLoading(false);
        setAccountData(res.data.data);
        setAccountTitle(res.data.data.accountTitle);
        setAccountNo(res?.data?.data?.accountNo)
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getCommissionPer = () => {
    setIsLoading(true);
    axios
      .get(get_commission)
      .then((res) => {
        setIsLoading(false);
        setCommissionData(res.data.data);
        setCommissionPer(res.data.data.commission);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSubsctiption();
    getCommissionPer();
    getAccountDetails();
  }, []);

  const handleUpdateSubscription = () => {
    if (subcriptionCharges == '') {
      setIsOPenSnackError(true);
      setSnackMessage('Please enter subscription');
    } else {
      let paylod = {
        charges: JSON.parse(subcriptionCharges),
      };
      setIsLoading(true);
      axios
        .put(`${update_subscription}/${subcriptionData._id}`, paylod)
        .then((res) => {
          console.log(res.data.data, 'Asdsadsadsad');
          setIsLoading(false);
          setSubscriptionCharges(res.data.data.charges);
          setIsOpenSnack(true);
          setSnackMessage('Subscription Updated Successfully');
          getSubsctiption();
        })
        .catch((err) => {
          setIsLoading(false);
          setIsOPenSnackError(true);
          setSnackMessage(err?.response?.data?.message);
        });
    }
  };
  const handleUpdateAccountDetails = () => {
    if (accountTitle == '') {
      setIsOPenSnackError(true);
      setSnackMessage('Please enter account title');
    }else if (accountNo == '') {
      setIsOPenSnackError(true);
      setSnackMessage('Please enter account no');
    } else {
      let paylod = {
        accountTitle: accountTitle,
        accountNo:accountNo,
      };
      setIsLoading(true);
      axios
        .put(`${update_account_Details}/${accountData._id}`, paylod)
        .then((res) => {
          console.log(res.data.data, 'Asdsadsadsad');
          setIsLoading(false);
          setAccountNo(res.data.data.accountNo);
          setAccountTitle(res.data.data.accountTitle);
          setAccountData(res.data.data);
          setIsOpenSnack(true);
          setSnackMessage('Account details Updated Successfully');
          getAccountDetails();
        })
        .catch((err) => {
          setIsLoading(false);
          setIsOPenSnackError(true);
          setSnackMessage(err?.response?.data?.message);
        });
    }
  };

  const handleAddAccountDetails = () => {
    if (accountTitle == '') {
      setIsOPenSnackError(true);
      setSnackMessage('Please enter account title');
    }else if (accountNo == '') {
      setIsOPenSnackError(true);
      setSnackMessage('Please enter account no');
    } else {
      let paylod = {
        accountTitle: accountTitle,
        accountNo:accountNo,
      };
      setIsLoading(true);
      axios
        .post(`${create_account_Details}`, paylod)
        .then((res) => {
          console.log(res.data.data, 'Asdsadsadsad');
          setIsLoading(false);
          setAccountNo(res.data.data.accountNo);
          setAccountTitle(res.data.data.accountTitle);
          setAccountData(res.data.data);
          setIsOpenSnack(true);
          setSnackMessage('Account details created Successfully');
          getAccountDetails();
        })
        .catch((err) => {
          setIsLoading(false);
          setIsOPenSnackError(true);
          setSnackMessage(err?.response?.data?.message);
        });
    }
  };
  const handleUpdateCommission = () => {
    if (commissionPer == '') {
      setIsOPenSnackError(true);
      setSnackMessage('Please enter commission');
    } else {
      let paylod = {
        commission: JSON.parse(commissionPer),
      };
      setIsLoading(true);
      axios
        .put(`${update_commission}/${commissionData._id}`, paylod)
        .then((res) => {
          setIsLoading(false);
          setSubscriptionCharges(res.data.data.charges);
          setIsOpenSnack(true);
          setSnackMessage('Commission Updated Successfully');
          getCommissionPer();
        })
        .catch((err) => {
          setIsLoading(false);
          setIsOPenSnackError(true);
          setSnackMessage(err?.response?.data?.message);
        });
    }
  };

  return (
    <NavBar>
      <Loader isloading={isLoading} />
      <Container maxWidth='xl'>
        <ErrorSnack
          open={isOpenSnackError}
          message={snackMessage}
          closeSnack={setIsOPenSnackError}
        />
        <SuccesSnack
          open={isOpenSnack}
          message={snackMessage}
          closeSnack={setIsOpenSnack}
        />
        <Grid container>
          <Grid item xs={12} md={4}>
            <TextField
              variant='outlined'
              placeholder='Subscription'
              label='Subscription'
              size='small'
              type='number'
              fullWidth
              value={subcriptionCharges}
              onChange={(e) => setSubscriptionCharges(e.target.value)}
            />
            <Button
              variant='contained'
              size='small'
              color='primary'
              // className={classes.createBtn}
              style={{
                textTransform: 'none',
                padding: '8px 40px',
                float: 'right',
                marginTop: '10px',
                marginRight: '1%',
              }}
              onClick={handleUpdateSubscription}
            >
              Update Subscription
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={12} md={4}>
            <TextField
              variant='outlined'
              placeholder='Commission'
              label='Commission'
              type='number'
              size='small'
              fullWidth
              value={commissionPer}
              onChange={(e) => setCommissionPer(e.target.value)}
            />
            <Button
              variant='contained'
              size='small'
              color='primary'
              // className={classes.createBtn}
              style={{
                textTransform: 'none',
                padding: '8px 40px',
                float: 'right',
                marginTop: '10px',
                marginRight: '1%',
              }}
              onClick={handleUpdateCommission}
            >
              Update Commission
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={12} md={4}>
            <TextField
              variant='outlined'
              placeholder='Account Title'
              label='Account Title'
              size='small'
              fullWidth
              value={accountTitle}
              onChange={(e) => setAccountTitle(e.target.value)}
            />
             <TextField
             style={{marginTop:20}}
              variant='outlined'
              placeholder='Account No'
              label='Account No'
              size='small'
              fullWidth
              value={accountNo}
              onChange={(e) => setAccountNo(e.target.value)}
            />
            {accountData == null ? 
            <Button
              variant='contained'
              size='small'
              color='primary'
              // className={classes.createBtn}
              style={{
                textTransform: 'none',
                padding: '8px 40px',
                float: 'right',
                marginTop: '10px',
                marginRight: '1%',
              }}
              onClick={handleAddAccountDetails}
            >
              Add Account Details
            </Button>
            :
            <Button
              variant='contained'
              size='small'
              color='primary'
              // className={classes.createBtn}
              style={{
                textTransform: 'none',
                padding: '8px 40px',
                float: 'right',
                marginTop: '10px',
                marginRight: '1%',
              }}
              onClick={handleUpdateAccountDetails}
            >
              Update Account Details
            </Button>
            }
          </Grid>
        </Grid>
      </Container>
    </NavBar>
  );
};

export default Settings;
