import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../navbar/AdminNavigation";
import {
  Container,
  TextField,
  Grid,
  Button,
  Select,
  FormControl,
  MenuItem,
  Paper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../styles";
import DateFnsUtils from "@date-io/date-fns";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import firebase from "firebase";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import AddIcon from "@material-ui/icons/Add";
import { Loader, successHelper, ErrorHelper } from "../../../components";
import { Delete } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import AppBar from "@material-ui/core/AppBar";
import { English, French, Japnese } from "../LanguageComponents";
import Alert from "@material-ui/lab/Alert";
import CreateEventDialog from "./CreateEventDialog";
import { get_event_detail_by_id } from "../../../api";
import { useParams } from "react-router-dom";

const EditEvent = ({ history }) => {
  const { data, fireBaseKey } = history.location.state;
  const params = useParams();

  const classes = useStyles();
  console.log(params, "asdlkajdlksa");

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCity, setSelectedCity] = React.useState("");
  const [dialogTitle, setDialogTitle] = useState("Create");

  const [company, setCompany] = React.useState([
    { name: "Suzuki", id: 0 },
    { name: "Toyota", id: 1 },
    { name: "Honda", id: 2 },
  ]);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [vehicleDetails, setVehicleDetails] = useState({
    vehicleName: "",
    registrationNumber: "",
    color: "",
    milage: "",
    price: "",
    description: "",
  });
  const [eventDetail, setEventDetail] = useState({
    eventName: "",
    eventDesscription: "",
    eventTicketPrice: "",
  });
  const [vehicleImage, setVehicleImages] = useState([]);
  const [videoFile, setVideoFile] = useState("");
  const [language, setLanuage] = useState("en");
  const [eventFirebaseKey, setEventFirebaseKey] = useState(null);

  // edit same Data
  const [selectedDatePost, setSelectedDatePost] = React.useState(new Date());
  const [selectedDateEventPost, setSelectedDateEventPost] = React.useState(
    new Date()
  );
  const [lastRegistrationDatePost, setLastRegistarionDatePost] = React.useState(
    new Date()
  );

  const [eventPrizes, setEventPrizes] = useState([]);
  const [isOpenModal, setIsOPenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [carArray, setCarArray] = useState([]);
  const [eventTicketImage, setEventTicketImage] = React.useState("");

  useEffect(() => {
    let eventFirebaseKeyUSe = uuidv4();
    setEventFirebaseKey(`${eventFirebaseKeyUSe}`);
    var options = {
      method: "GET",
      url: "https://private-anon-f35f62ca96-carsapi1.apiary-mock.com/manufacturers",
      headers: {
        "x-rapidapi-host": "cis-automotive.p.rapidapi.com",
        "x-rapidapi-key": "b4828b2f2bmsh4af44d2999f44a5p109fb4jsn594365bc0fea",
      },
    };
    axios
      .request(options)
      .then(function (response) {
        let arr = [...response.data, { name: "Suzuki" }];
        console.log(arr, "ressssspinsee");
        setCarArray(arr);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  let dateFormatter = (value) => {
    let selectedEventFormatted = new Date();
    let registrationDate = value?.split("-");
    if (registrationDate) {
      selectedEventFormatted.setDate(registrationDate[2]);
      selectedEventFormatted.setMonth(registrationDate[1] - 1);
      selectedEventFormatted.setFullYear(registrationDate[0]);
      return selectedEventFormatted;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${get_event_detail_by_id}/${params.id}`)
      .then((res) => {
        let result = res.data.data;
        setEventPrizes(result.eventPrizes);
        setVehicleImages(result.eventImages);
        setEventTicketImage(result.eventTicketImage);
        setEventDetail({
          eventName: result.eventName,
          eventDesscription: result.eventDescription,
          eventTicketPrice: result.eventTicketPrice,
        });
        setSelectedDateEventPost(dateFormatter(result?.eventEndDate));
        setLastRegistarionDatePost(
          dateFormatter(result?.eventLastRegistrationDate)
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err, "laksdjlsakjd");
        setIsLoading(false);
      });
  }, []);

  const onChangePicture = async (e, index) => {
    const sizLimitInBytes = 10000000;
    if (e.target.files[0].size < sizLimitInBytes) {
      setIsLoading(true);
      console.log(e, "eeeeee");
      const form = new FormData();
      form.append("file", e.target.files[0]);
      form.append("upload_preset", "awfemeq5");
      try {
        let res = await axios.post(
          "https://api.cloudinary.com/v1_1/auctionproject/upload",
          form
        );
        if (res) {
          if (res.data.resource_type == "video") {
            setVideoFile(res.data.secure_url);
            setIsLoading(false);
          } else {
            let imgs = [...vehicleImage];
            imgs.push(res.data.secure_url);
            // imgs[index] =
            setVehicleImages(imgs);
            // setImage(res.data.secure_url)
            console.log(res, "ressssssss");
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error, "ERRRRR=>>>>>");
        setIsLoading(false);
      }
    } else {
      alert("File Size Limit Exceed, Please Select File Less Than 10 MB");
    }
  };
  const onUploadTicket = async (e, index) => {
    const sizLimitInBytes = 10000000;

    if (e.target.files[0].size < sizLimitInBytes) {
      setIsLoading(true);
      const form = new FormData();
      form.append("file", e.target.files[0]);
      form.append("upload_preset", "awfemeq5");
      try {
        let res = await axios.post(
          "https://api.cloudinary.com/v1_1/auctionproject/upload",
          form
        );
        if (res) {
          setEventTicketImage(res.data.secure_url);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error, "ERRRRR=>>>>>");
        setIsLoading(false);
      }
    } else {
      alert("File Size Limit Exceed, Please Select File Less Than 10 MB");
    }
  };

  const refrences = useRef();
  const ticketRef = useRef();

  let handleRef = () => {
    refrences.current.click();
  };
  let handleTicketRef = () => {
    ticketRef.current.click();
  };

  const renderImageCard = (value, index) => {
    let handleDelte = () => {
      let img = [...vehicleImage];
      img.splice(index, 1);
      setVehicleImages(img);
    };

    return (
      <Card className={classes.eventCardRoot}>
        <CardMedia className={classes.eventCard} image={value} />
        <div
          onClick={handleDelte}
          style={{
            cursor: "pointer",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            paddingTop: "3px",
            paddingTop: "3px",
            color: "#fff",
            backgroundColor: "#ec5a4f",
            fontSize: "16px",
          }}
        >
          Delete
          <Delete
            style={{ color: "#fff", fontSize: "18px", marginTop: "1px" }}
          />
        </div>
      </Card>
    );
  };
  const handleLangChange = (event, newValue) => {
    setLanuage(newValue);
  };

  return (
    <NavBar>
      <CreateEventDialog
        setEventPrizes={setEventPrizes}
        eventPrizes={eventPrizes}
        openModal={isOpenModal}
        setOpen={setIsOPenModal}
        type={dialogTitle}
        data={eventPrizes}
        setIsOpenSnack={false}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      <Container maxWidth="xl">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <Typography gutterBottom variant="h6" component="h2">
            {/* Vehicle Details */}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "15px",
              justifyContent: "space-around",
              width: "45%",
            }}
          >
            <Button
              onClick={(_) => {
                setIsOPenModal(true);
                setDialogTitle("Update");
              }}
              style={{
                textAlign: "center",
                marginTop: "0px",
                fontSize: "12px",
                height: "30px",
              }}
              variant="contained"
              color="primary"
              disabled={eventPrizes?.length === 3}
            >
              Add Prize
            </Button>
            <Button
              onClick={handleRef}
              style={{
                textAlign: "center",
                marginTop: "0px",
                fontSize: "12px",
                height: "30px",
              }}
              variant="contained"
              color="primary"
            >
              Add Image
            </Button>
            <Button
              disabled={videoFile}
              onClick={handleRef}
              style={{ textAlign: "center", fontSize: "12px", height: "30px" }}
              variant="contained"
              color="primary"
            >
              Add Video
            </Button>
            <Button
              onClick={handleTicketRef}
              style={{
                textAlign: "center",
                fontSize: "12px",
                height: "30px",
              }}
              variant="contained"
              color="primary"
            >
              Upload Ticket Image
            </Button>
          </div>
          <input
            type="file"
            hidden
            ref={refrences}
            onChange={(e) => onChangePicture(e)}
          />
          <input
            type="file"
            hidden
            ref={ticketRef}
            onChange={(e) => onUploadTicket(e)}
          />
        </div>
        {videoFile ? (
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom variant="h6" component="h2">
                Video
              </Typography>
              <Card className={classes.eventCardRootVideo}>
                <CardMedia
                  className={classes.eventCardVdeo}
                  src={videoFile}
                  component="video"
                  controls
                />
                <div
                  onClick={(_) => setVideoFile(null)}
                  style={{
                    cursor: "pointer",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "3px",
                    color: "#fff",
                    backgroundColor: "#ec5a4f",
                    fontSize: "16px",
                  }}
                >
                  Delete
                  <Delete
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      marginTop: "1px",
                    }}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        ) : null}
        {eventTicketImage !== "" ? (
          <>
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              style={{ marginTop: "1%", marginBottom: "-5px" }}
            >
              Event Ticket Image
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Card className={classes.eventCardRoot}>
                  <CardMedia
                    className={classes.eventCard}
                    image={eventTicketImage}
                  />
                  <div
                    onClick={(_) => setEventTicketImage("")}
                    style={{
                      cursor: "pointer",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      paddingTop: "3px",
                      paddingTop: "3px",
                      color: "#fff",
                      backgroundColor: "#ec5a4f",
                      fontSize: "16px",
                    }}
                  >
                    Delete
                    <Delete
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        marginTop: "1px",
                      }}
                    />
                  </div>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : null}
        {vehicleImage.length > 0 && (
          <Typography
            gutterBottom
            variant="h6"
            component="h2"
            style={{ marginTop: "1%", marginBottom: "-5px" }}
          >
            Images
          </Typography>
        )}
        <Grid container spacing={2}>
          {vehicleImage.map((val, index) => {
            return (
              <Grid item xs={12} sm={3}>
                {renderImageCard(val, index)}
              </Grid>
            );
          })}
        </Grid>
        <English
          eventTicketImage={eventTicketImage}
          uploadedVideos={videoFile}
          uploadVehicleImages={vehicleImage}
          eventFirebaseKey={fireBaseKey}
          type={"Update"}
          eventData={data}
          setVehicleImageEdit={setVehicleImages}
          setVideoEdit={setVideoFile}
          setSelectedDatePost={setSelectedDatePost}
          setLastRegistarionDatePost={setLastRegistarionDatePost}
          setSelectedDateEventPost={setSelectedDateEventPost}
          selectedDatePost={selectedDatePost}
          selectedDateEventPost={selectedDateEventPost}
          lastRegistrationDatePost={lastRegistrationDatePost}
          eventPrizes={eventPrizes}
          setEventDetail={setEventDetail}
          eventDetail={eventDetail}
        />
        <Typography gutterBottom variant="h6" component="h2">
          Event Prizes
        </Typography>
        <Grid container spacing={4}>
          {eventPrizes?.map((prize, index) => {
            console.log(prize, "prize");
            return (
              <Grid item xs={12} md={4}>
                <Card className={classes.eventCardRoot}>
                  <CardContent>
                    <Typography gutterBottom>{prize.prizeNo}</Typography>
                    <Typography gutterBottom>
                      Vehicle Name: {prize.Vehicle_Name}
                    </Typography>
                    <Typography gutterBottom>
                      Vehicle Number: {prize.Registration_Number}
                    </Typography>
                    <Typography gutterBottom>Price: {prize.price}</Typography>
                    <Typography gutterBottom>
                      Description: {prize.description}
                    </Typography>
                    <Typography gutterBottom>
                      Color: {prize.Vehicle_Color}
                    </Typography>
                    <Typography gutterBottom>
                      City: {prize.Registration_City}
                    </Typography>

                    <Button
                      onClick={(_) => {
                        setIsOPenModal(true);
                        setSelectedIndex(index);
                        setDialogTitle("Edit");
                      }}
                      fullWidth
                      className={classes.eventEditButton}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {"Edit"}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Loader isloading={isLoading} />
      </Container>
    </NavBar>
  );
};

export default EditEvent;
