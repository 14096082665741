import React, { useState, useEffect } from 'react';
import NavBar from '../../../navbar/AdminNavigation';
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TablePagination,
  Grid,
  Button,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useStyles } from '../styles';
import firebase from 'firebase';
import { Loader, successHelper, ErrorHelper } from '../../../components';
import {
  controller_register,
  get_all_controller,
  update_controller_status,
} from '../../../api';
import axios from 'axios';
import { Delete, Edit } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

let controllerNav = [
  {
    name: 'Dashboard',
    link: 'dashboard',
    icon: 'dashboard',
    selected: false,
  },
  {
    name: 'Users',
    link: 'Users',
    icon: 'group',
    selected: false,
  },
  {
    name: "FAQ's",
    link: 'Faq',
    icon: 'quiz',
    selected: false,
  },
  {
    name: 'Support',
    link: 'Support',
    icon: 'support_agent',
    selected: false,
  },
  {
    name: 'Car Features',
    link: 'carFeatures',
    icon: 'directions_car',
    selected: false,
  },
  {
    name: 'Post Car',
    link: 'AddPost',
    icon: 'post_add',
    selected: false,
  },
  {
    name: 'Settings',
    link: 'Settings',
    icon: 'settings',
    selected: false,
  },
];

const CreateController = () => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [controllersData, setControllersData] = useState({
    name: '',
    email: '',
    password: '',
    status: true,
  });
  const [allControllers, setAllControllers] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [navigationData, setNavigationData] = useState(controllerNav);

  const tableHeading = ['ID', 'Name', 'Email', 'Status'];

  useEffect(() => {
    getAllControllers();
  }, []);

  const getAllControllers = () => {
    setIsLoading(true);
    axios
      .get(get_all_controller)
      .then((res) => {
        console.log(res.data.data, 'sadsadsadasd');
        setIsLoading(false);
        setAllControllers(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.data.data, 'errrr');
      });
  };

  const controllersTable = () => {
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    console.log(allControllers, 'allControllersallControllers');

    const handleChangeSwitch = (key) => {
      let paylod = {
        userId: key._id,
        // status: true,
        status: !key.status,
      };

      axios
        .put(update_controller_status, paylod)
        .then((res) => {
          console.log(res, 'sadsadsadasd');
          successHelper.handleSuccess(
            'Controller Status Updated Succesfully',
            true
          );
          setIsLoading(false);
          getAllControllers();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err, 'errrr');
        });
    };

    return (
      <Paper>
        <TableContainer component={Paper} style={{ padding: '20px' }}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label='simple table'
          >
            <TableHead>
              <TableRow>
                {tableHeading.map((heading, index) => {
                  return (
                    <TableCell
                      align='center'
                      style={{
                        fontWeight: 'bold',
                        fontSize: '13px',
                        padding: '6px',
                      }}
                    >
                      {heading}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {allControllers?.length > 0 &&
                allControllers?.map((row, ind) => (
                  <TableRow key={ind} className={classes.tableRow}>
                    <TableCell align='center' className={classes.rowName}>
                      {ind + 1}
                    </TableCell>
                    <TableCell align='center' className={classes.rowName}>
                      {row.name}
                      {console.log(row.status, 'statusstatus')}
                    </TableCell>
                    <TableCell align='center' className={classes.rowName}>
                      {row.email}
                    </TableCell>
                    <TableCell align='center' className={classes.rowName}>
                      <Switch
                        checked={row.status}
                        onChange={() => handleChangeSwitch(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={allControllers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    );
  };

  const handleCreate = () => {
    let { name, email, password, status } = controllersData;

    let res = navigationData.findIndex((val) => val.selected == true);

    let filterArr = navigationData.filter((item) => item.selected == true);

    if (name == '') {
      ErrorHelper.handleErrors('Name is required', true);
    } else if (email == '') {
      ErrorHelper.handleErrors('email is required', true);
    } else if (password == '') {
      ErrorHelper.handleErrors('Password is required', true);
    } else if (res == -1) {
      ErrorHelper.handleErrors('Please select atleast one screen', true);
    } else {
      let paylod = {
        name,
        email,
        password,
        role: 'Controller',
        status,
        permissions: filterArr,
      };
      setIsLoading(true);
      axios
        .post(controller_register, paylod)
        .then((res) => {
          console.log(res, 'sadsadsadasd');
          successHelper.handleSuccess('Controller Created  Succesfully', true);
          let tempArr = [];

          navigationData.map((item) => {
            tempArr.push({
              ...item,
              selected: false,
            });
          });
          setNavigationData(tempArr);
          getAllControllers();
          setControllersData({
            name: '',
            email: '',
            password: '',
            status: true,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err, 'errrr');
        });
    }
  };

  const handleChangeCheckbox = (item, ind) => {
    let tempArr = [...navigationData];
    tempArr[ind].selected = !tempArr[ind].selected;
    setNavigationData(tempArr);
  };

  return (
    <NavBar>
      <Container maxWidth='xl'>
        <Typography gutterBottom variant='h6' component='h2'>
          Create Controller
        </Typography>
        <Grid
          container
          spacing={2}
          style={{ marginTop: '5px', marginBottom: '3%' }}
        >
          <Loader isloading={isLoading} />

          <Grid item xs={12} md={4}>
            <TextField
              variant='outlined'
              placeholder='Name'
              label='Name'
              size='small'
              fullWidth
              value={controllersData.name}
              onChange={(e) =>
                setControllersData({ ...controllersData, name: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant='outlined'
              placeholder='Email'
              size='small'
              label='Email'
              fullWidth
              value={controllersData.email}
              onChange={(e) =>
                setControllersData({
                  ...controllersData,
                  email: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant='outlined'
              placeholder='Password'
              size='small'
              label='Password'
              fullWidth
              value={controllersData.password}
              onChange={(e) =>
                setControllersData({
                  ...controllersData,
                  password: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant='h6' component='h2'>
              Select Screen
            </Typography>
            {navigationData.map((item, ind) => {
              return (
                <FormControlLabel
                  style={{ marginRight: '20px' }}
                  control={
                    <Checkbox
                      checked={item.selected}
                      onChange={() => handleChangeCheckbox(item, ind)}
                      value={item.selected}
                      color='primary'
                    />
                  }
                  label={item.name}
                />
              );
            })}
          </Grid>
        </Grid>
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={handleCreate}
            style={{ textAlign: 'right', marginTop: '15px', width: 'auto' }}
            variant='contained'
            color='primary'
            fullWidth
          >
            Create
          </Button>
        </div>
        <div>
          <Typography gutterBottom variant='h6' component='h2'>
            Controllers
          </Typography>
          {controllersTable()}
        </div>
      </Container>
    </NavBar>
  );
};

export default CreateController;
